@import styles/sass/abstracts

.root
  padding: 4rem 0
  +res-sm-max
    padding: 2rem 0 0
  &:not(.root--scrolled)
    .stickyHead
      height: 0
      opacity: 0

.toggle
  margin-bottom: 2rem

.tableWrap
  position: relative
  overflow-x: auto
  margin-left: #{$wcont-padding * -1}
  margin-right: #{$wcont-padding * -1}
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  +res-md-min
    +horizontalScrollbarStyle
  &::-webkit-scrollbar
    +res-sm-max
      height: 0
  +res-sm-max
    padding-left: 0
    padding-right: 0
    &:not(:first-child)
      border-top: 1px solid $stroke-color
  &--loading
    overflow-x: visible

.stickyHead
  position: sticky
  top: $header-height
  left: 0
  right: 0
  z-index: 1
  color: $text-white
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  margin-left: #{$wcont-padding * -1}
  margin-right: #{$wcont-padding * -1}
  opacity: 1
  transition: opacity .3s
  background: $fill-secondary-90
  +res-md-min
    display: none

.stickyHeadList
  display: flex

.stickyHeadItem
  padding: 2rem 1.6rem
  &:not(:first-child)
    padding-left: 0

.table
  border-collapse: collapse
  width: 100%
  th
    position: relative
    vertical-align: top
    text-align: left
    padding: 0 2rem 4rem
    &:not(:first-child)
      +res-md-min
        min-width: 31.2rem
      +res-sm-max
        min-width: 24rem
    +res-sm-max
      padding: 2rem
      &:not(:nth-child(2))
        padding-left: 0

  td
    padding: 1.6rem
    border: 1px solid $stroke-color
    +res-sm-max
      border-width: 1px 0 
      min-width: 21.7rem
    &:not(:nth-child(2))
      +res-sm-max
        padding-left: 0
      .rowTitle
        visibility: hidden

    &.addCompareProductRow
      +res-md-min
        border: none
      

  td, th
    +res-sm-max
      &:first-child
        display: none

  tr
    +res-sm-max
      &:last-child
        td
          border-bottom: 0

.rowTitle
  +typoTextSm
  font-weight: $fw-bold
  margin-bottom: .8rem
  white-space: nowrap
  +res-md-min
    display: none

.rowTitleDesktop
  font-weight: $fw-bold

.diffRow
  &--hidden
    display: none

.addCompareProductHeader
  position: relative

.addCompareProductHeaderContent
  position: absolute
  top: 0
  bottom: 4rem
  left: 2rem
  right: 2rem
  padding: 0 5rem
  background: $fill-neutral-20
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 2rem
  white-space: nowrap
  +res-md-max
    padding: 0 1.6rem
    left: 1rem
    right: 1rem
    bottom: 2rem

.addCompareProductHeaderIcon
  svg
    +styleIcon(6rem, $fill-primary-70)

.empty
  text-align: center
  +vSpace(4rem, 2.4rem)
  +res-md-min
    border: 1px solid $stroke-color
    padding: 4rem 3.2rem
  +res-sm-max
    padding: 2rem 0 4rem

.emptyTitle
  +typoH3
  +res-sm-max
    +typoH4Mobile

  
\:global(.toolbar ~ .page)
  .stickyHead
    +res-menu-desktop-min
      top: #{$header-height + $toolbar-height}
    +res-menu-mobile-max
      top: #{$header-height-mobile + $toolbar-height}


\:global(.preheader ~ .page)
  .stickyHead
    +res-menu-desktop-min
      top: #{$header-height + $preheader-height}
    +res-menu-mobile-max
      top: #{$header-height-mobile + $preheader-height-mobile}


\:global(.toolbar ~ .preheader ~ .page)
  .stickyHead
    +res-menu-desktop-min
      top: #{$header-height + $preheader-height + $toolbar-height}
    +res-menu-mobile-max
      top: #{$header-height-mobile + $preheader-height-mobile + $toolbar-height}
