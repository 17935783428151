@import styles/sass/abstracts

.layout
  padding: 4rem 0
  +vSpace(1.6rem)
  +res-sm-max
    padding: 2rem 0 

.form
  +vSpace(1.6rem)

.checkboxGroup
  +vSpace(.8rem)

.privacy, .requirements
  color: $text-grey-dark
  +typoTextSm

.privacy
  margin-bottom: 1.6rem
  a
    +typoLink

// .requirements
//   text-align: center

.cta
  width: 100%