@import styles/sass/abstracts

.form
  +vSpace(2.4rem)

.ctaBox
  +res-sm-min
    display: flex
    align-items: center
    gap: 1.6rem
    > *
      min-width: 17.5rem
      &:first-child
        order: 99
  +res-xs-max
    +vSpace(2.4rem)
    > *
      width: 100%
.info
  color: $text-grey-dark
  +typoTextSm
