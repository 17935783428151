@import styles/sass/abstracts

.root
  padding: 1.6rem 0
  color: $text-white
  background: $fill-secondary-90
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  z-index: 5001
  &:not(.root--open)
    display: none
  +res-md-max
    padding: 2.6rem 0 1.2rem
    cursor: pointer

.root
  &::before
    content: ""
    position: absolute
    top: 1.2rem
    height: .4rem
    width: 5.6rem
    left: 0
    right: 0
    margin: 0 auto
    border-radius: .2rem
    background: $fill-white
    +res-lg-min
      display: none

.layout
  display: flex
  align-items: center
  gap: 2rem
  +res-md-max
    gap: 1rem

.head
  +vSpace(.4rem)
  flex: 1
  +res-lg-min
    display: none

.title
  +typoTextLg
  font-weight: $fw-semibold

.description
  +typoTextSm

.list
  flex: 1
  display: flex
  max-width: 97rem
  gap: 2rem
  margin-right: auto
  +res-md-max
    display: none
  > *
    flex: 1

.close
  +styleIcon(2rem, $fill-white)
  &:focus-visible
    +focusVisibleStdDark

.modal
  bottom: 0
  left: 0
  top: auto
  width: 100%
  max-width: none
  background: $background-light
  padding: 3.4rem 0 2rem
  color: $text-black
  +scrollbarStyle

.modalLayout
  +vSpace(2rem)
  &::before
    background: $fill-grey-dark

.modalCloseBar
  position: absolute
  left: 0
  top: 0

.modalHead
  display: flex
  align-items: flex-start
  justify-content: space-between
  gap: 2rem

.modalTitle
  +typoH5

.modalDescription
  +typoTextSm

.modalClose
  svg
    +styleIcon(2.4rem, $fill-black)

.modalList
  +vSpace(.8rem)

.modalResetBox, .modalCtaBox
  text-align: center

.modalCtaBox
  > *
    +res-xs-max
      width: 100%