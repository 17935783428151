@import styles/sass/abstracts

.image
  &--desktop, &--tablet, &--mobile
    display: none
  &--desktop
    +res-lg-min
      display: block
  &--tablet
    +res-md-only
      display: block
  &--mobile
    +res-sm-max
      display: block