@import styles/sass/abstracts

.root
  padding: 8rem 0
  background: $background-light
  +borderer
  +res-sm-max
    padding: 2rem 0
  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi NumbersCard"

.list, .root :global(.block-card-group)
  display: flex
  flex-wrap: wrap
  gap: 2rem
  > *
    width: calc(25% - 1.5rem)
    +res-lg-max
      width: calc(33.33% - 1.33rem)
    +res-md-max
      width: calc(50% - 1rem)
    @media screen and (max-width: 374px)
      width: 100%
