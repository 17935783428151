@import styles/sass/abstracts

.root
  position: relative
  color: $text-black
  &:not(.root--main)
    .title
      +res-md-min
        +multilineEllipsis(2, 4.2rem)

  &--main
    +res-md-min
      background: $fill-neutral-30
      display: flex
      align-items: center
      gap: 4rem
      > *
        width: calc(50% - 2rem)
      .main
        padding-right: 4rem
      .imageBox
        height: 42.4rem

  &--play
    .imageBox
      opacity: 0
      pointer-events: none

.main
  +vSpace(1.2rem)
  padding: 1.2rem 0

.imageBox
  position: relative
  height: 30rem
  +res-sm-max
    height: 20rem
  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(#000, .24)
    z-index: 1

.head
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: .8rem

.title
  +typoH5
  +res-md-min
    +typoH3
  +res-sm-max
    +multilineEllipsis(2, 2.6rem)

.sottotitolo
  +typoTextMd
  +res-sm-max
    display: none

.play
  position: absolute
  transform: translateX(-50%) translateY(-50%)
  top: 50%
  left: 50%
  z-index: 2
  svg
    +styleIcon(6.4rem, $fill-white)
    +res-sm-max
      +sizeIcon(4rem)

.cta
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 3