@import styles/sass/abstracts

.root
  max-width: 37.5rem
  max-height: none
  width: 100%
  height: 100%
  top: 0
  bottom: 0
  left: 0
  right: auto
  display: flex
  flex-direction: column
  &:not(.root--open)
    display: none
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color


.head
  padding: 2rem $wcont-padding
  display: flex
  align-items: flex-start
  justify-content: space-between
  gap: 1.6rem

.title
  +typoH5

.close
  margin-top: 1px
  svg
    +styleIcon(2.4rem, $fill-black)
  &:focus-visible
    outline-offset: 0

.body
  flex: 1
  overflow-y: auto
  +scrollbarStyle
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color

.filtersBlock
  padding: 1.6rem $wcont-padding

.resetBox
  text-align: center
  padding: 2rem $wcont-padding 3.2rem


.footer
  padding: 1rem $wcont-padding 2rem
  display: flex
  align-items: center
  gap: 1rem
  > *
    width: calc(50% - .5rem)
