@import styles/sass/abstracts

.root
  background: $fill-neutral-20
  color: $text-black
  border: 1px solid $stroke-color
  display: block
  @media print
    page-break-inside: avoid

.imageBox
  background: $fill-white
  height: 18rem

.main
  padding: 1.2rem 1.2rem 3.2rem
  +vSpace(.6rem)

.title
  +typoH6

.description
  +typoTextSm
  +customContent
  color: $text-grey-dark