@import styles/sass/abstracts

.root
  position: fixed
  top: 0
  left: 0
  right: 0
  background: $fill-accent-1
  color: $text-black
  padding: .4rem 0
  +typoTextSm
  text-align: center
  height: $preheader-height
  z-index: 3001
  +res-sm-max
    +typoTextXs
    height: $preheader-height-mobile

  @media print
    display: none!important

  &--no-desktop
    +res-md-min
      display: none
  &--no-mobile
    +res-sm-max
      display: none

.content
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none

\:global(.toolbar) ~ .root
  top: $toolbar-height