@import 'styles/sass/abstracts'
  
.root
  position: relative
  &--filtroRadio
    .options
      +vSpace(1.2rem)
  &--horizontal, &--horizontal-half, &--horizontal-buttons
    .options
      display: flex
      flex-wrap: wrap
      gap: 2rem
      +vSpace(0)
  &--horizontal-half
    .options
      +vSpace(0)
      +res-sm-max
        gap: 1.6rem
      > *
        width: calc(50% - 1rem)
        +res-sm-max
          width: 100%
  &--horizontal-buttons
    .options
      gap: .8rem
  
.inputWrap
  position: relative
  display: block
  cursor: pointer
  color: $text-black

.main
  display: flex
  gap: 2rem

.content
  +vSpace(.4rem)
  padding-left: 3.2rem
  padding-top: .15rem
  flex: 1
  .label   
    padding-left: 0

.contentHead
  display: flex
  align-items: center
  justify-content: space-between
  gap: 2rem

.label    
  display: block
  padding-left: 3.2rem
  padding-top: .15rem
  font-weight: $fw-semibold

.price
  white-space: nowrap
  font-weight: $fw-semibold

.loghi
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  gap: 1rem 2rem
  +res-xs-max
    flex-direction: column
  img
    max-height: 4rem
    width: auto

.input 
  position: absolute
  left: -9999px

  &:focus-visible
    &+ .styledInput
      outline: 2px solid $fill-black
      outline-offset: -3px
      border-radius: 50%
      
.styledInput
  position: absolute
  left: 0
  top: 0
  svg
    +styleIcon(2.4rem, $fill-black, $fill-primary-70)

.option
  min-height: 2.4rem
  +typoTextSm
  &:not(.option--disabled)
    .inputWrap
      cursor: pointer
    &:hover
      .input:checked
        &+ .styledInput
          svg
            +colorIcon($fill-black, $fill-black)
  &--disabled
    svg
      +colorIcon($fill-grey-dark, $fill-grey-dark)

  &--prodottoCard
    .label
      font-weight: $fw-regular

    .input:checked
      ~ .label, ~ .content .label, ~ .content .price
        font-weight: $fw-bold

  &--filtroRadio
    .label
      font-weight: $fw-regular


  &--horizontal-buttons
    .styledInput
      display: none
    .label
      padding: .5rem 1.7rem
      border-radius: 2.4rem
      border: 1px solid $stroke-color
    .input
      &:checked
        ~ .label
          background: $fill-neutral-30

.options
  +vSpace(2rem)