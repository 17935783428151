@import styles/sass/abstracts

.root
  padding: 4rem 0
  &--lg
    .content
      max-width: 55.4rem

.layout
  +res-md-min
    border: 1px solid $stroke-color
    padding: 4rem 3.2rem

.content
  max-width: 41.3rem
  margin-left: auto
  margin-right: auto
  +vSpace(2.4rem)

.icon
  display: block
  svg
    +styleIcon(4rem, $fill-black)
    margin-left: auto
    margin-right: auto

.title
  +typoH3
  text-align: center
  +res-sm-max
    +typoH4

.description
  color: $text-grey-dark
  text-align: center
  +typoTextLg
  +customContent
  +res-sm-max
    +typoTextMd
