@import styles/sass/abstracts

.root
  height: 56.8rem
  &--editMode
    .iframe
      pointer-events: none
    &.root--empty
      display: flex
      align-items: center
      justify-content: center
      &::before
        content: "Imposta id Issuue"
        +typoPagebuilderHint

.iframe
  width: 100%
  height: 100%
  border: none
  display: block