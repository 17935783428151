@import styles/sass/abstracts

.root
  display: flex
  align-items: center
  gap: 1.6rem
  color: $text-grey-dark
  padding: 1.6rem
  border: 1px solid $stroke-color
  +typoTextSm
  &:focus-visible
    outline-offset: -2px

.icon
  +styleIcon(2.4rem, $fill-black)

.label
  flex: 1