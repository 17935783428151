@import styles/sass/abstracts

.root
  position: relative
  color: $text-black
  text-align: left
  +vSpace(4rem)

.close
  position: absolute
  right: 0
  top: 0

.main
  +vSpace(1.6rem)

.imageBox
  width: 11rem
  height: 11rem
  
.content
  +vSpace(1.2rem)

.category
  display: flex
  flex-wrap: wrap
  gap: .4rem
  +typoTextSm
  font-weight: $fw-medium
  text-transform: uppercase
  color: $text-grey-dark

.title, .price--current
  +typoTextLg
  font-weight: $fw-semibold

.prices
  > *
    &:not(:last-child)
      margin-right: .4rem

.price
  &--listino
    text-decoration: line-through
    color: $text-grey-dark
