@import styles/sass/abstracts

.root
  padding: 2rem
  +vSpace(1.6rem)
  color: $text-black
  transition: background .3s
  cursor: pointer
  &--selected
    background: $fill-neutral-30
  
.main
  +vSpace(.8rem)

.title
  +typoTextLg
  font-weight: $fw-semibold

.details
  +typoTextSm
  +vSpace(.8rem)
  +customContent

