@import styles/sass/abstracts

.root
  &--cart
    +res-sm-max
      border-top: 1px solid $stroke-color
      padding-top: 2rem

.layout
  +res-md-min
    +vSpace(2rem)

.main
  > *
    &:not(:last-child)
      margin-bottom: 2rem
      border-bottom: 1px solid $stroke-color
      padding-bottom: 2rem

.productsList
  +vSpace(1.6rem)
  
.list
  color: $text-grey-dark
  +typoTextSm
  +vSpace(1rem)

.item, .total
  display: flex
  align-items: center
  justify-content: space-between
  gap: .8rem

.itemValue
  strong
    font-weight: $fw-bold

.total
  +typoTextLg
  font-weight: $fw-semibold

.info
  svg
    +styleIcon(2rem, currentColor)

.cta
  width: 100%
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    margin-bottom: 4rem
    +res-md-min
      display: none
