@import styles/sass/abstracts

.layout
  +vSpace(1.6rem)

.form
  +vSpace(2.4rem)

.privacyMessage
  +typoTextSm
  color: $text-grey-dark
  +customContent  

.ctaBox
  text-align: center

.notes
  text-align: center
  color: $text-grey-dark
  +typoTextSm