@import styles/sass/abstracts

.root
  display: flex
  align-items: center
  gap: .8rem
  border: 1px solid $stroke-color
  border-radius: 4rem
  padding: .3rem 1.1rem
  background: $fill-neutral-20
  color: $text-black 
  +typoTextMd

.icon
  svg
    +styleIcon(2rem, $fill-black)

