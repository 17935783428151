@import styles/sass/abstracts

.root
  padding: 6.4rem 0
  +res-sm-max
    padding: 2rem 0 4rem

.layout
  +vSpace(2.4rem)

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.swiper
  +res-lg-max
    +widen($wcont-padding)