@import styles/sass/abstracts

.root
  padding: 1.1rem
  border: 1px solid $stroke-color
  +vSpace(2rem)

.feedbackField
  display: flex
  flex-wrap: wrap
  gap: 2rem

.feedbackLabel
  +typoTextSm
  margin-bottom: 0
  width: auto

.thankyou
  +res-md-min
    display: flex
    align-items: flex-end
    gap: 2rem
  +res-sm-max
    +vSpace(2rem)

.thankyouContent
  +typoTextSm
  color: $text-grey-dark
  +customContent
  +res-md-min
    flex: 1
