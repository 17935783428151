@import styles/sass/abstracts

.root
  top: 0
  left: 0
  height: 100dvh
  max-width: none
  max-height: none
  +res-menu-desktop-min
    &--level1
      width: $menu-panel-width
    &--level2
      width: #{$menu-panel-width * 2}
  +res-menu-mobile-max
    width: 100%
    max-width: 60rem

.head
  padding: 0 $wcont-padding
  background: $fill-white
  display: flex
  align-items: center
  justify-content: space-between  
  border-bottom: 1px solid $stroke-color
  height: $menu-mobile-head-height
  +res-menu-desktop-min
    display: none

.logo
  svg
    display: block
    width: 10rem
    height: 3.5rem

.close
  svg
    +styleIcon(2.4rem, $fill-black)