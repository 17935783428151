@import styles/sass/abstracts

.root
  position: relative
  width: 100%
  gap: 2rem
  color: $text-black
  +res-md-min
    display: flex
    align-items: center
    justify-content: space-between
    gap: 2rem
  +res-sm-max
    +vSpace(.8rem)      

.content
  +vSpace(.8rem)
  +res-md-min
    flex: 1
    max-width: 69.6rem

.title
  +typoTextLg
  font-weight: $fw-semibold

.description
  +typoTextSm
  color: $text-grey-dark
  
.cta
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1