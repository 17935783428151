@import styles/sass/abstracts

.root
  padding: 7.2rem 0
  background: $fill-white
  color: $text-black
  &--neutrallight
    background: $fill-neutral-20
  &--secondarydark
    background: $fill-secondary-90
    color: $text-white
    .description
      +customContentDarkVariant
  +res-sm-max
    padding: 2rem 0 0

.layout
  +res-md-min
    display: flex
    align-items: center
    gap: 8rem 
  +res-sm-max
    +vSpace(2rem)

.main
  +vSpace(2rem)
  +res-md-min
    flex: 1

.content
  +vSpace(1.2rem)

.title
  +typoH2
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd

.imageBox
  +res-md-min
    max-width: 30%
    aspect-ratio: 1 / 1
  +res-sm-max
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
    height: 30rem
  img
    display: block