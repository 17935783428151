@import styles/sass/abstracts

.ROOT
    position: relative
    display: block
    width: 100%
    padding: 0px
    min-height: 75px
    background-color: white

    &--editing
        padding: 10px

    &--old-style
        +customContent
        .block-ricerca-prodotto-card-section + .block-ricerca-prodotto-card-section
            border-top: 1px solid $stroke-color
            .block-ricerca-prodotto-card
                &:first-child
                    margin-top: 4.1rem
