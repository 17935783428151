@import styles/sass/abstracts

.layout
  padding: 4rem 0
  +vSpace(4rem, 2rem)
  +res-sm-max
    padding-top: 0

.main
  +vSpace(4rem, 2rem)

.list
  +res-md-min
    display: flex
    flex-wrap: wrap
    gap: 4rem
  +res-sm-max
    +vSpace(2rem)

.item
  +res-md-min
    width: calc(50% - 2rem)
  &--full
    +res-md-min
      width: 100%

.ctaBox
  text-align: center