@import styles/sass/abstracts

.layout
  +res-lg-min
    display: flex
    gap: 4rem

.sidebar
  border-right: 1px solid $stroke-color
  padding: 2.4rem 0
  flex: 1
  max-width: 32.5rem
  +res-md-max
    display: none

.breadcrumbs
  width: 100%

.back
  +res-lg-min 
    display: none

.main
  +vSpace(4rem, 2rem)
  padding: 4rem 0
  +res-lg-min
    max-width: 82.3rem
    width: calc(100% - 32rem)
  +res-md-max
    padding: 2rem 0

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextMd
  +res-sm-max
    +typoTextSm
