@import styles/sass/abstracts

.root
  padding: 4rem 0
  color: $text-black

.layout
  +vSpace(4rem)

.main
  +vSpace(1.2rem)
  max-width: 86.4rem
  margin-left: auto
  margin-right: auto
  text-align: center

.title
  +typoH2
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextXl
  +customContent
  +res-sm-max
    +typoTextMd

.slider
  display: none
  \:global(.swiper)
    +res-lg-max
      +widen($wcont-padding)
  &--visible
    display: block