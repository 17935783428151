@import styles/sass/abstracts

.root
  padding: 8rem 0
  color: $text-black
  +res-sm-max
    padding: 4rem 0
  &--neutrallight
    background: $fill-neutral-20
  &--secondarydark
    background: $fill-secondary-90
    color: $text-white
    .description
      +customContentDarkVariant

  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi GallerySlide"

.layout
  +vSpace(4rem, 2rem)

.main
  +vSpace(1.2rem)
  text-align: center

.title
  +typoH2
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd

.list, .root :global(.block-card-group)
  +vSpace(2rem)

.slider
  \:global(.swiper)
    +widen($wcont-padding)
