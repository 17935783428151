$swiperThemeColor: red
$swiperCenteredOffsetBefore: 0
$swiperCenteredOffsetAfter: 0
$swiperWrapperTransitionTimingFunction: linear

.swiper
  margin-left: auto
  margin-right: auto
  position: relative
  overflow: hidden
  list-style: none
  padding: 0
  /* Fix of Webkit flickering */
  z-index: 1
  display: block
  
  &-vertical > &-wrapper
    flex-direction: column

  &-wrapper
    position: relative
    width: 100%
    height: 100%
    z-index: 1
    display: flex
    transition-property: transform
    transition-timing-function: var($swiperWrapperTransitionTimingFunction, initial)
    box-sizing: content-box
    @media print
      flex-wrap: wrap
      box-sizing: border-box
      row-gap: 2rem

.swiper-android .swiper-slide,
.swiper-ios .swiper-slide,
.swiper-wrapper
  transform: translate3d(0px, 0, 0)

.swiper-horizontal
  touch-action: pan-y

.swiper-vertical
  touch-action: pan-x

.swiper-slide
  flex-shrink: 0
  width: 100%
  height: 100%
  position: relative
  transition-property: transform
  display: block

  &-invisible-blank
    visibility: hidden

/* Auto Height */
.swiper-autoheight,
.swiper-autoheight .swiper-slide
  height: auto

.swiper-autoheight .swiper-wrapper
  align-items: flex-start
  transition-property: transform, height

.swiper-backface-hidden .swiper-slide
  transform: translateZ(0)
  -webkit-backface-visibility: hidden
  backface-visibility: hidden

/* 3D Effects */
.swiper-3d.swiper-css-mode .swiper-wrapper
  perspective: 1200px

.swiper-3d .swiper-wrapper
  transform-style: preserve-3d

.swiper-3d
  perspective: 1200px

.swiper-3d .swiper-slide,
.swiper-3d .swiper-cube-shadow
  transform-style: preserve-3d

/* CSS Mode */
.swiper-css-mode > .swiper-wrapper
  overflow: auto
  scrollbar-width: none
  /* For Firefox */
  -ms-overflow-style: none
  /* For Internet Explorer and Edge */

  &::-webkit-scrollbar
    display: none

  & > .swiper-slide
    scroll-snap-align: start start

.swiper-css-mode.swiper-horizontal > .swiper-wrapper
  scroll-snap-type: x mandatory

.swiper-css-mode.swiper-vertical > .swiper-wrapper
  scroll-snap-type: y mandatory

.swiper-css-mode.swiper-free-mode > .swiper-wrapper
  scroll-snap-type: none

  & > .swiper-slide
    scroll-snap-align: none

.swiper-css-mode.swiper-centered > .swiper-wrapper::before
  content: ''
  flex-shrink: 0
  order: 9999

.swiper-css-mode.swiper-centered > .swiper-wrapper > .swiper-slide
  scroll-snap-align: center center
  scroll-snap-stop: always

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child
  margin-inline-start: $swiperCenteredOffsetBefore

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper::before
  height: 100%
  min-height: 1px
  width: $swiperCenteredOffsetAfter

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child
  margin-block-start: $swiperCenteredOffsetBefore

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper::before
  width: 100%
  min-width: 1px
  height: $swiperCenteredOffsetAfter

/* Slide styles start */
/* 3D Shadows */
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  pointer-events: none
  z-index: 10

.swiper-3d .swiper-slide-shadow
  background: rgba(0, 0, 0, 0.15)

  &-left
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

  &-right
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

  &-top
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

  &-bottom
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

.swiper-lazy-preloader
  width: 42px
  height: 42px
  position: absolute
  left: 50%
  top: 50%
  margin-left: -21px
  margin-top: -21px
  z-index: 10
  transform-origin: 50%
  box-sizing: border-box
  border: 4px solid var(--swiper-preloader-color, $swiperThemeColor)
  border-radius: 50%
  border-top-color: transparent

.swiper:not(.swiper-watch-progress) .swiper-lazy-preloader,
.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader
  animation: swiper-preloader-spin 1s infinite linear

.swiper-lazy-preloader-white
  --swiper-preloader-color: #fff

.swiper-lazy-preloader-black
  --swiper-preloader-color: #000

@keyframes swiper-preloader-spin
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

///////// PAGINATION ///////////

.swiper-pagination
  position: absolute
  text-align: center
  transition: 300ms opacity
  transform: translate3d(0, 0, 0)
  z-index: 10
  @media print
    display: none!important

.swiper-pagination.swiper-pagination-hidden
  opacity: 0

.swiper-pagination-disabled > .swiper-pagination,
.swiper-pagination.swiper-pagination-disabled
  display: none !important

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal
  bottom: 0
  left: 0
  width: 100%

/* Bullets */
.swiper-pagination-bullets-dynamic
  overflow: hidden
  font-size: 0

  & .swiper-pagination-bullet
    transform: scale(0.33)
    position: relative

  & .swiper-pagination-bullet-active
    transform: scale(1)

  & .swiper-pagination-bullet-active-main
    transform: scale(1)

  & .swiper-pagination-bullet-active-prev
    transform: scale(0.66)

  & .swiper-pagination-bullet-active-prev-prev
    transform: scale(0.33)

  & .swiper-pagination-bullet-active-next
    transform: scale(0.66)

  & .swiper-pagination-bullet-active-next-next
    transform: scale(0.33)

// .swiper-pagination-bullet
//   width: 1rem
//   height: 1rem
//   display: inline-block
//   border-radius: 50%
//   background: rgba($ottanio-100, .5)
//   transition: background .3s
//   &:focus-visible
//     +focusVisibleStd

//   &button
//     border: none
//     margin: 0
//     padding: 0
//     box-shadow: none
//     -webkit-appearance: none
//     appearance: none

//   .swiper-pagination-clickable &
//     cursor: pointer

//   &:only-child
//     display: none !important

//   &-active
//     background: $ottanio-100

// .swiper.swiper--dark
//   .swiper-pagination-bullet
//     background: rgba($white, .5)
//     &:focus-visible
//       outline-color: $kiwi
//     &-active
//       background: $white

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets
  right: 8px
  top: 50%
  transform: translate3d(0px, -50%, 0)

.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet
  margin: 6px 0
  display: block

.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  top: 50%
  transform: translateY(-50%)
  width: 8px

.swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet
  display: inline-block
  transition: 200ms transform, 200ms top

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet
  margin: 0 .6rem

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  left: 50%
  transform: translateX(-50%)
  white-space: nowrap

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet
  transition: 200ms transform, 200ms left

.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet
  transition: 200ms transform, 200ms right

/* Fraction */
.swiper-pagination-fraction
  color: inherit

/* Progress */
.swiper-pagination-progressbar
  background: $background-light
  position: relative
  border: 1px solid $stroke-color

  & &-fill
    background: $fill-primary-70
    position: absolute
    left: -1px
    top: -1px
    width: calc(100% + 2px)
    height: calc(100% + 2px)
    transform: scale(0)
    transform-origin: left top

  .swiper-rtl & &-fill
    transform-origin: right top

.swiper.swiper--dark
  .swiper-pagination-progressbar
    background: none
    &-fill
      background: $fill-white    


.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite
  width: 100%
  height: .8rem
  max-width: 61.4rem
  left: 0
  right: 0
  bottom: 0
  margin-left: auto
  margin-right: auto
  margin-top: 4rem

.swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite
  width: 4px
  height: 100%
  left: 0
  top: 0

.swiper-pagination-lock, .swiper-button-lock
  display: none

.swiper-button-next, .swiper-button-prev
  position: absolute
  top: calc(50% - 1.2rem)
  background: $fill-white
  border-radius: 50%
  z-index: 1
  padding: .2rem
  cursor: pointer
  @media print
    display: none!important
  &:focus-visible
    +focusVisibleStd
    outline-offset: -2px
  &::after
    content: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z" fill="%23161616"/></svg>')
    display: block
    width: 2rem
    height: 2rem
  &.swiper-button-disabled
    display: none

.swiper-button-prev
  left: 2rem

.swiper-button-next
  right: 2rem
  &::after
    transform: rotate(180deg)

.swiper-notification
  position: absolute
  bottom: 0
  right: 0
  z-index: -1
  opacity: 0

.swiper--bottomNav
  text-align: right
  &::after
    content: ""
    clear: both
  .swiper-wrapper
    text-align: left
  .swiper-button-next, .swiper-button-prev
    position: relative
    left: auto
    right: auto
    bottom: auto
    margin-top: 1.6rem
    display: inline-block
    width: 5.6rem
    gap: .8rem
    background: $fill-neutral-30
    padding: 1.6rem
    &::after
      content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z" fill="%23161616"/></svg>')
      width: 2.4rem
      height: 2.4rem
    // &.swiper-button-disabled
    //   background: none
    &.swiper-button-lock
      display: none
  .swiper-button-next
    margin-left: .8rem

  .swiper-pagination
    float: left
    width: 33.5rem
    max-width: 50%
    +res-sm-max
      max-width: 100%
      margin-top: 2.4rem

  .swiper-button-next, .swiper-button-prev
    +res-sm-max
      display: none

.swiper--arrowNav
  .swiper-button-next, .swiper-button-prev
    padding: .6rem
    background: $fill-neutral-30
    &.swiper-button-disabled
      background: $fill-white
      display: block
    &::after
      content: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z" fill="%23161616"/></svg>')

