@import styles/sass/abstracts

.root
  display: flex
  align-items: center
  justify-content: center
  padding: 2rem 0
  
.logo
  display: block
  svg
    display: block
    width: 13rem
    height: 4.7rem