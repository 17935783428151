@import styles/sass/abstracts

.root
  padding: 8rem 0
  color: $text-black
  background: $background-light
  +borderer
  +res-sm-max
    padding: 4rem 0
  +spacer
  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi Button"
  &--black, &--secondarydark
    color: $text-white
  &--neutrallight
    background: $fill-neutral-20
  &--neutrallight2
    background: $fill-neutral-30
  &--black
    background: $fill-black
  &--secondarydark
    background: $fill-secondary-90
  &--sm
    padding: 4rem 0
    +res-sm-max
      padding: 2rem 0
    .layout
      +vSpace(1rem)
    .title
      +typoH5
    .description
      +typoTextSm
      +res-sm-max
        +typoTextSm

  &--center
    text-align: center
    .layout
      margin-left: auto
      margin-right: auto

    .ctaBox, :global(.block-card-group)
      justify-content: center

.layout
  +vSpace(2rem)
  max-width: 108rem

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max
    +typoTextMd

.ctaBox, .root :global(.block-card-group)
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 1.2rem
  +res-xs-max
    flex-direction: column
  