@import styles/sass/abstracts

.root
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: space-between
  padding: 2.4rem 0 4rem
  +vSpace(6.4rem)
  border-right: 1px solid $stroke-color
  +res-md-max
    display: none

.list
  +vSpace(2.4rem)

.block
  +vSpace(1.6rem)

.blockTitle
  +typoTextSm
  color: $text-grey-dark
  text-transform: uppercase

    