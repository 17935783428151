@import styles/sass/abstracts

.root
  \:global(.swiper)
    +res-sm-max
      margin-left: #{$wcont-padding * -1}
      margin-right: #{$wcont-padding * -1}
      padding-left: $wcont-padding
      padding-right: $wcont-padding
  \:global(.swiper-slide)
    width: 23.1rem!important
    height: auto
    > *
      height: 100%
  \:global(.swiper-pagination)
    +res-md-min
      display: none