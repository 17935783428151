@import styles/sass/abstracts

.root
  +vSpace(2rem)
  +res-sm-max
    padding-bottom: 2rem

.list
  border: 1px solid $stroke-color
  padding: 3.1rem
  +vSpace(2rem)
  > *
    &:not(:last-child)
      padding-bottom: 2rem
      border-bottom: 1px solid $stroke-color
  +res-sm-max
    border-left: none
    border-right: none
    padding: 2.4rem 2rem
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}

.itemContent
  +typoTextSm
  +customContent