@import styles/sass/abstracts

.root
  color: $text-black
  background: $fill-neutral-30
  +maxResBounds
  
  &:not(.root--home)
    padding: 6.4rem 2rem
    +res-lg-min
      display: flex
      justify-content: center
      align-items: center
      gap: 2rem
      > *
        width: calc(50% - 1rem)
        max-width: 55.6rem
    +res-md-max
      padding: 4rem 2rem
      +vSpace(1.6rem)

    .content
      +vSpace(1.2rem)
      padding-right: 2rem
    
    .formRow
      +res-sm-min
        display: flex
        align-items: flex-start
        gap: 1.4rem
      +res-xs-max
        +vSpace(1.4rem)

    .formEmail
      +res-sm-min
        flex: 1

    .formSubmit
      +res-xs-max
        width: 100%


  &--home
    background: $fill-primary-70
    color: $text-white
    +res-md-min
      display: flex
      align-items: stretch
      > *
        width: 50%

    .content
      +vSpace(1.6rem)
      padding: 2rem $wcont-padding
      +res-md-min
        align-self: center
        padding-right: 7.2rem
        max-width: 66.8rem
        margin-left: auto

    .title
      +typoH2 
      +res-sm-max
        +typoH3Mobile

    .description
      +customContentDarkVariant
      +res-md-min
        +typoTextXl

    .main
      background: $background-light
      color: $text-black

    .newsletter
      padding: 4rem
      +res-sm-max
        padding: 2rem $wcont-padding

    .formRow
      +res-sm-lg-min
        display: flex
        align-items: flex-start
        gap: 1.4rem
      +res-xs-md
        +vSpace(1.4rem)

    .formEmail
      +res-sm-lg-min
        flex: 1

    .formSubmit
      +res-xs-md
        width: 100%


.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextMd
  +customContent

.newsletter
  +vSpace(2rem)
  > *
    &.newsletterSubtitle
      margin-bottom: 1.6rem

.formEmailLabel
  font-weight: $fw-semibold

.newsletterTitle
  +typoH4

.newsletterSubtitle
  +typoTextSm
  font-weight: $fw-semibold

.form
  +vSpace(2rem)


.imageBox
  height: 23rem