@import styles/sass/abstracts

.root
  color: #000
  &--nohidden
    .hide
      svg
        display: block

.actions
  display: flex
  gap: 3rem

.list
  display: flex
  flex-wrap: wrap
  gap: 2.4rem
  margin-top: 2.4rem
  svg
    +styleIcon(6rem, currentColor)
    margin-left: auto
    margin-right: auto
    
.title
  +typoTextSm
  font-weight: $fw-bold
  margin-bottom: .4rem

.hide
  svg
    display: none

.item
  display: flex
  > *
    padding: 1rem 0
    flex: 1
    width: 10rem
    

.light
  background: #FFF
.dark
  background: #000
  color: #FFF
