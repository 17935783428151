@import styles/sass/abstracts

.root
  +vSpace(1.6rem)
  color: $text-black

.title
  +typoH6

.content
  background-color: $fill-neutral-20
  padding: 2.4rem
  +typoTextMd
  +res-sm-max
    padding: 2rem