@import styles/sass/abstracts

.root
  position: relative
  height: 46rem
  +res-sm-max
    height: 33rem
  img
    width: 100%
    height: 100%
  
.cta
  position: absolute
  left: $wcont-padding
  right: $wcont-padding
  width: fit-content
  margin: 0 auto
  bottom: 2rem