@import styles/sass/abstracts

.form
  +vSpace(2rem)

.privacy, .requirements
  color: $text-grey-dark
  +typoTextSm

.privacy
  a
    +typoLink