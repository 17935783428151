@import styles/sass/abstracts

.root
  padding: 4rem
  text-align: center
  color: $text-black
  margin: auto
  &:focus-visible
    outline: none

.main
  +vSpace(2.4rem)

.icon
  display: block
  svg
    +styleIcon(4rem, $fill-black)
    margin: 0 auto
    animation: rotation 1.2s linear infinite

.title
  +typoH4

.description
  +typoTextMd

