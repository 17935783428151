@import styles/sass/abstracts

.root
  background: $background-light
  border: 1px solid $stroke-color
  color: $text-black
  &--evidenza
    background: $fill-secondary-90
    color: $text-white
    border: none
    display: flex
    align-items: center

.main
  flex: 1
  padding: 2rem
  +vSpace(.8rem)

.imageBox
  width: 24rem
  height: 100%

.head
  display: flex
  align-items: center
  justify-content: space-between

.time
  display: flex
  align-items: center
  gap: .8rem
  +typoTextSm

.timeIcon
  svg
    +styleIcon(2.4rem, $fill-white)

.title
  +typoTextLg
  font-weight: $fw-semibold
