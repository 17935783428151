@import 'styles/sass/abstracts'
  
.root
  +typoTextXs
  color: $text-error
  text-align: left
  margin-top: .4rem
  &--global
    +typoTextSm
    margin-top: 0
    margin-bottom: 2.4rem

.label
  display: block
