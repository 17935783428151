.topbar
    width: 100%
    height: 35px
    display: flex
    align-items: center
    background-color: $pb-grey-90
    color: white
    z-index: 5
    position: relative
    border-bottom: 1px solid $pb-grey-100

    &__items
        height: 100%
        display: flex
        align-items: center
        > *
            display: flex
            align-items: center
        &--previews
            > p
                padding: 0 10px
                +typoTextSm
        &--main
            margin-left: auto
        &--advanced
            width: 0
            overflow-x: hidden
            transition: width .3s
            &.visible
                width: 74px // con 3 bottoni => 111px
    
        &__center 
            margin-left: auto
            margin-right: auto

    &__item
        background-color: $pb-grey-90
        color: $pb-grey-20
        height: 100%
        border-left: 1px solid $pb-grey-100
        display: flex
        align-items: center
        padding: 5px 8px
        cursor: pointer      

        &:hover
            color: $pb-white

        > *
            &:not(:last-child)
                margin-right: 5px

        svg
            +style-icon(20px, currentColor)
                    
        span
            font-size: 12px
            line-height: 20px

        &.disabled
            cursor: not-allowed
            color: #9298a2
            background: #616771

        &--success
            svg
                color: $pb-success-light

        &--warning
            svg
                color: $pb-warning-light

        &--error
            svg
                color: $pb-error-light

        &--undo
            padding-left: 5px
            padding-right: 5px
        
        &--redo
            padding-left: 5px
            padding-right: 5px
            border-left: 0

        &--save-status
            border-left: none
            svg
                width: 16px
                height: 16px
