// COLORS
$stroke-color: var(--stroke-color, red)
$background-light: var(--background-light, red)
$background-light-r: var(--background-light-r, 255)
$background-light-g: var(--background-light-g, 0)
$background-light-b: var(--background-light-b, 0)
$text-black: var(--text-black, red)
$text-grey-dark: var(--text-grey-dark, red)
$text-white: var(--text-white, red)
$text-primary-70: var(--text-primary-70, red)
$text-primary-80: var(--text-primary-80, red)
$text-idee-verdi: var(--text-idee-verdi, red)
$text-disabled: var(--text-disabled, red)
$text-error: var(--text-error, red)
$text-batteria: var(--text-batteria, red)
$fill-black: var(--fill-black, red)
$fill-black-r: var(--fill-black-r, red)
$fill-black-g: var(--fill-black-g, red)
$fill-black-b: var(--fill-black-b, red)
$fill-grey-dark: var(--fill-grey-dark, red)
$fill-white: var(--fill-white, red)
$fill-primary-70: var(--fill-primary-70, red)
$fill-primary-80: var(--fill-primary-80, red)
$fill-primary-40: var(--fill-primary-40, red)
$fill-primary-10: var(--fill-primary-10, red)
$fill-secondary-90: var(--fill-secondary-90, red)
// $fill-secondary-20: var(--fill-secondary-20, red) -- non utilizzato
$fill-neutral-30: var(--fill-neutral-30, red)
$fill-neutral-20: var(--fill-neutral-20, red)
$fill-neutral-20-r: var(--fill-neutral-20-r, red)
$fill-neutral-20-g: var(--fill-neutral-20-g, red)
$fill-neutral-20-b: var(--fill-neutral-20-b, red)
$fill-idee-verdi: var(--fill-idee-verdi, red)
$fill-success: var(--fill-success, red)
$fill-success-10: var(--fill-success-10, red)
$fill-error: var(--fill-error, red)
$fill-error-10: var(--fill-error-10, red)
$fill-batteria: var(--fill-batteria, red)
$fill-accent-1: var(--fill-accent-1, red)
$fill-disabled: var(--fill-disabled, red)
$border-primary-70: var(--border-primary-70, red)
$border-black: var(--border-black, red)

// Fonts

$ff-primary: var(--font-inter), sans-serif
$ff-secondary: var(--font-manrope), sans-serif

$fw-light: 300
$fw-regular: 400
$fw-medium: 500
$fw-semibold: 600
$fw-bold: 700
$fw-extrabold: 800
$fw-black: 900

// Space
$space-lg: 8rem
$space-lg-mobile: 4rem
$space-md: 4rem
$space-md-mobile: 2rem
$space-sm: 2rem
$space-sm-mobile: 2rem

// Gradients

// Breakpoints

$res-xs-max: 575px
$res-sm-min: 576px
$res-sm-max: 767px
$res-md-min: 768px
$res-md-max: 991px
$res-lg-min: 992px
$res-lg-max: 1199px
$res-xl-min: 1200px
$res-max: 1920px

// Containers 

$wcont-width-md: 133.6rem
$wcont-width-md-px: 1336px
$wcont-width-sm: 99.6rem
$wcont-width-sm-plus: 112rem
$wcont-width-xs: 90rem
$wcont-width-blog-head: 112rem
$wcont-width-blog-content: 86.4rem

$wcont-padding: 2rem

// Layout

$toolbar-height: 4rem

$header-top-height: 3.3rem
$header-main-height: 6.2rem
$header-height: 9.5rem // somma dei due precedenti

$header-main-height-mobile: 6.7rem
$header-search-height-mobile: 5rem
$header-height-mobile: 11.7rem // somma dei due precedenti

$preheader-height: 2.9rem
$preheader-height-mobile: 2.6rem

$menu-panel-width: 44rem
$menu-mobile-head-height: 6.7rem

// Admin variables (toolbar e menuItem)

$admin-main-default: $fill-primary-70
$admin-main-dark: $fill-primary-80

$admin-accent-dark: #646c9a
$admin-accent-default: #5d78ff
$admin-accent-light: #c4cff9

$admin-text-default: $fill-black
$admin-text-light: #74788d

$admin-button-border: $fill-primary-70

$admin-white: #fff

$admin-shadow: 0 .4rem 1.2rem .4rem rgba(0, 0, 0, 0.12)
