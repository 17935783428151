@import styles/sass/abstracts

.root
  padding: 2.4rem
  background-color: $fill-primary-10
  +res-md-min
    display: flex
    align-items: center
    gap: 4rem
  +res-sm-max
    +vSpace(2rem)
    padding: 2rem

  &--full
    +res-lg-min
      .progress
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: space-between
        gap: 1.2rem
        +vSpace(0)
      .progressBar
        width: 100%
        order: 999

.content
  +res-md-min
    flex: 1

.title
  +typoH5

.description
  margin-top: 1.2rem
  +typoTextSm

.progress
  +vSpace(1.2rem)
  
.progressLabel
  +typoTextMd
  span
    font-weight: $fw-semibold

.button
  white-space: nowrap