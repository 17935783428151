@import styles/sass/abstracts

.root
  display: flex
  align-items: flex-start
  gap: 1.2rem

.icon
  +styleIcon(3.2rem, currentColor)

.main
  +vSpace(1.2rem)
  flex: 1

.title
  +typoH6

.description
  +typoTextLg
  +customContent
