@mixin typoBase($size, $lineHeight, $weight: null, $family: null)
  @if $family
    font-family: $family
  @if $weight
    font-weight: $weight
  font-size: $size
  line-height: $lineHeight

// CUSTOMIZE

@mixin typoH1
  +typoBase(6.4rem, 1.3, $fw-extrabold, $ff-secondary)

@mixin typoH2
  +typoBase(4rem, 1.3, $fw-extrabold, $ff-secondary)

@mixin typoH3
  +typoBase(3.2rem, 1.3, $fw-extrabold, $ff-secondary)

@mixin typoH4
  +typoBase(2.6rem, 1.3, $fw-extrabold, $ff-secondary)

@mixin typoH5
  +typoBase(2rem, 1.3, $fw-extrabold, $ff-secondary)

@mixin typoH6
  +typoBase(1.8rem, 1.3, $fw-extrabold, $ff-secondary)

@mixin typoH2Mobile
  +typoBase(4rem, 1.3)

@mixin typoH3Mobile
  +typoBase(3.2rem, 1.3)

@mixin typoH4Mobile
  +typoBase(2.6rem, 1.3)

@mixin typoH5Mobile
  +typoBase(2rem, 1.3)

@mixin typoH6Mobile
  +typoBase(1.8rem, 1.3)

@mixin typoTextXs
  +typoBase(1.2rem, 1.5) 

@mixin typoTextSm
  +typoBase(1.4rem, 1.5)

@mixin typoTextMd
  +typoBase(1.6rem, 1.5)

@mixin typoTextLg
  +typoBase(1.8rem, 1.5) 

@mixin typoTextXl
  +typoBase(2rem, 1.5) 

@mixin typoTextXxl
  +typoBase(2.4rem, 1.5) 

@mixin typoTextXxxl
  +typoBase(3.2rem, 1.5) 

@mixin typoButtonMd
  +typoBase(1.6rem, 1.5, $fw-bold, $ff-secondary)

@mixin typoButtonSm
  +typoBase(1.4rem, 2rem, $fw-bold, $ff-secondary)
  
@mixin typoButtonXs
  +typoBase(1.2rem, 1.5, $fw-bold, $ff-secondary)


@mixin typoLink
  font-weight: $fw-semibold
  text-decoration: underline
  color: $text-primary-80
  &:focus-visible
    outline-offset: .2rem

@mixin typoLinkDarkVariant
  color: $text-white
  &:focus-visible
    outline-color: $text-white
    

@mixin typoPagebuilderHint
  +typoBase(1.2rem, 1.5)
  font-style: italic
  color: $text-grey-dark
  text-align: center
  display: block