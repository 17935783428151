@import styles/sass/abstracts

.root
  position: relative
  padding: 4rem 0
  color: $text-black
  +res-sm-max
    padding: 2rem 0 0
  &::before
    +res-md-min
      content: ""
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 13.7rem
      max-height: 100%
      background: linear-gradient(0deg, rgba($fill-black-r, $fill-black-g, $fill-black-b, 0) 0%, $fill-black 100%)
      z-index: 1

.layout
  position: relative
  z-index: 2
  +res-sm-max
    +vSpace(2rem)

.main
  +vSpace(2rem)
  text-align: center
  +res-md-min
    margin-top: 24rem
    padding: 4rem
    background: $background-light
    > *
      max-width: 108rem
      margin-left: auto
      margin-right: auto
  +res-sm-max
    padding-bottom: 2rem

.content
  +vSpace(2rem)

.intro
  +typoTextLg
  font-weight: $fw-semibold
  color: $text-primary-70

.title
  +typoH1
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd

.imageBox
  +res-md-min
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 50rem
    z-index: 0

.mobileImage
  height: auto
  +res-md-min
    display: none

.desktopImage
  +res-sm-max
    display: none