@import styles/sass/abstracts

.root
  position: relative
  width: fit-content
  &:hover
    .tooltip
      opacity: 1
      visibility: visible
      pointer-events: auto

  &--left
    .tooltip
      right: 100%
      top: 50%
      transform: translateY(-50%)
      padding-right: .6rem
      &::after
        top: calc(50% - .6rem)
        right: -.6rem
        border-left-color: $fill-black
  &--right
    .tooltip
      left: 100%
      top: 50%
      transform: translateY(-50%)
      padding-left: .6rem
      &::after
        top: calc(50% - .6rem)
        left: -.6rem
        border-right-color: $fill-black
  &--top 
    .tooltip
      left: 50%
      transform: translateX(-50%)
      bottom: 100%
      padding-bottom: .6rem
      &::after
        left: calc(50% - .6rem)
        bottom: -.6rem
        border-top-color: $fill-black
  &--bottom
    .tooltip
      left: 50%
      transform: translateX(-50%)
      top: 100%
      padding-top: .6rem
      &::after
        left: calc(50% - .6rem)
        top: -.6rem
        border-bottom-color: $fill-black

.tooltip
  position: absolute
  visibility: hidden
  pointer-events: none
  opacity: 0
  width: 40rem
  max-width: calc(100vw - 5rem)
  transition: opacity .3s
  z-index: 1000
  &::after
    content: ""
    position: absolute
    border: .6rem solid transparent

.content
  border-radius: .8rem
  padding: 1.2rem
  background: $fill-black
  color: $text-white
  text-align: center
  +typoTextSm
  +customContent
