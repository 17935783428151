
.block-text
    // white-space: pre-wrap !important

.block-button
    white-space: pre-wrap !important


// CKEditor
.cke_editable[contenteditable="false"]
    cursor: default

.cke_float
    left: 39px !important
    top: 35px !important
    width: calc(100% - 39px - 220px) !important
    background-color: white !important
    z-index: 3 !important

.cke_focus
    outline: none !important

.cke_editor_ckeditor-no-toolbar
    display: none !important

.cke_toolbar_break
    display: none !important

.ckeditor-hide
    display: none !important
.ckeditor-show
    display: block !important

.ROOT--editing
    .text-editor__wrap
        white-space: pre-wrap !important
    .block-button
        white-space: pre-wrap !important