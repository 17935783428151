@import styles/sass/abstracts

.root
  position: relative
  display: flex
  align-items: flex-start
  width: 100%
  gap: 2rem
  color: $text-black
  &--md
    +res-md-min
      align-items: center
      .main
        display: flex
        gap: 2rem
        +vSpace(0)

      .content
        flex: 1

      .imageBox
        width: 12rem
        height: 12rem

      .prices
        text-align: right
        +vSpace(.8rem)

.imageBox
  width: 7rem
  height: 7rem

.main
  +vSpace(.8rem)
  flex: 1

.content
  +vSpace(.8rem)

.tag
  text-transform: uppercase

.battery
  display: flex
  align-items: center
  gap: .4rem
  color: $text-batteria
  +typoTextSm
  font-weight: $fw-medium
  text-transform: uppercase

.batteryIcon
  svg
    +styleIcon(2.1rem, currentColor)

.title
  +typoTextLg
  font-weight: $fw-semibold

.link
  display: block
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
  &:focus-visible
    outline: none
    &::after
      +focusVisibleStd

.formato
  +typoTextSm

.prices, .tag, .battery
  position: relative
  z-index: 1
  width: fit-content

.prices
  +vSpace(.4rem)

.price
  +typoTextSm
  &--current
    +typoTextLg
    font-weight: $fw-bold
  &:not(.price--current)
    color: $text-grey-dark
    .priceValue
      text-decoration: line-through
