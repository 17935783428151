@import styles/sass/abstracts

.root
  display: flex
  align-items: center
  gap: .8rem
  padding: .6rem 3.2rem .6rem .6rem
  &:hover
    .title
      text-decoration: underline
  &:focus-visible
    outline-color: $border-primary-70
    outline-offset: -2px

.imageBox
  width: 5.4rem
  height: 5.4rem

.title
  +typoTextLg
