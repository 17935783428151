@import styles/sass/abstracts

.iframe
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  border: none
  background: #000

.root
  position: relative
  width: 100%
  height: 100%
  &--editMode
    &.root--empty
      &::before
        content: "Configura il video"
        +typoPagebuilderHint
        text-align: center
        padding: 1rem
  &--iframe
    &.root
      &--4-3, &--16-9, &--9-16
        &::after
          content: ""
          display: block
          width: 100%

      &--4-3
        &::after
          padding-bottom: 75%

      &--16-9
        &::after
          padding-bottom: 56.25%

      &--9-16
        &::after
          padding-bottom: 177.78%