@import styles/sass/abstracts

.root
  +res-sm-min
    display: flex
    width: 100%
    gap: 1.6rem
  +res-xs-max
    +vSpace(1.6rem)

.inputWrap
  position: relative
  +res-sm-min
    flex: 1

.input
  padding: .7rem 1.1rem .7rem 3.9rem
  border: 1px solid $stroke-color
  border-radius: 4rem
  background: $fill-neutral-20
  width: 100%
  +typoTextMd
  color: $text-black
  transition: border-color .2s, box-shadow .2s, background-color .2s
  box-shadow: 0 0 0 0 rgba($fill-black, 0)
  +placeholderStyle
    color: $text-black
  &::-webkit-search-cancel-button
    display: none
  &:focus
    border-color: $fill-black
    box-shadow: 0 0 0 1px $fill-black

.icon
  position: absolute
  left: 1.2rem
  top: calc(50% - 1rem)
  svg
    +styleIcon(2rem, $fill-black)

.submit
  +res-xs-max
    width: 100%