@import 'styles/sass/abstracts'
  
.root
  display: inline-flex
  align-items: center
  gap: .8rem
  color: $text-grey-dark
  +typoTextXs
  > *
    &:not(:last-child)
      &::after
        content: "/"
  &--dark
    color: $text-white
  &--mobile-dark
    +res-sm-max
      color: $text-white
  &--mobile-light
    +res-sm-max
      color: $text-grey-dark

.item
  display: flex
  align-items: center
  gap: .8rem

.itemLink
  text-decoration: underline
