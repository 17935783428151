@import styles/sass/abstracts

.form
  +vSpace(2.4rem)

.fieldsets
  > *
    &:not(:last-child)
      margin-bottom: 2.4rem
      padding-bottom: 2.4rem
      border-bottom: 1px solid $stroke-color

.ctaBox
  +res-sm-min
    display: flex
    align-items: center
    gap: 1.6rem
    > *
      min-width: 17.5rem
      &:first-child
        order: 99
  +res-xs-max
    +vSpace(2rem)
    > *
      width: 100%