@import styles/sass/abstracts

.root
  display: flex
  align-items: center
  gap: 1rem
  +typoTextMd
  color: $text-black
  transition: background-color .3s
  width: 100%
  text-align: left
  &--image
    padding: .8rem
    &.root--level0
      font-weight: $fw-semibold
    &.root--open
      background-color: $fill-neutral-30
    &:hover:not(:disabled)
      background-color: $fill-neutral-20
  &--green
    color: $text-idee-verdi

  &--icon
    padding: .8rem 1.6rem

  &:not(.root--icon):not(.root--image)
    &.root--level0
      padding: .6rem 1.6rem

    &.root--level2
      padding: .3rem 0

.imageBox
  img
    display: block
    width: 4rem
    height: 4rem

.label
  flex: 1

.arrow, .icon
  svg
    +styleIcon(2.4rem, $fill-black)
