@import styles/sass/abstracts

.form
  +vSpace(2.4rem)

.buttons
  display: flex
  gap: 1.6rem
  +res-sm-max
    gap: 2rem
    flex-direction: column
    > *
      width: 100%

.requirements
  color: $text-grey-dark
  +typoTextSm
  +customContent
