@import styles/sass/abstracts

.root
  padding: 1.2rem
  border: 1px solid $stroke-color
  background: $fill-white
  color: $text-grey-dark
  +typoTextSm
  display: flex
  flex-direction: column
  &--inSlider
    // se in slider, anche nella versione mobile devo mantenere l'altezza minima della parte bassa
    .bottom
      min-height: 17.4rem
    
.head
  display: flex
  align-items: flex-start
  min-height: 3.2rem

.tags
  display: flex
  gap: .4rem

.fav, .compare
  margin-left: auto

.imageBox
  height: 18rem
  display: block

.main
  +vSpace(.6rem)
  padding: .4rem 0
  flex: 1

.utilizzo
  min-height: 2.4rem

.heading
  +vSpace(.6rem)

.category
  display: flex
  flex-wrap: wrap
  gap: .4rem
  font-weight: $fw-medium
  text-transform: uppercase

  &--battery
    color: $text-batteria
    
.categoryLabel
  +typoTextSm

.categoryIcon
  svg
    +styleIcon(2.1rem, $fill-batteria)

.titleLink
  display: block

.title
  +typoTextLg
  font-weight: $fw-semibold
  color: $text-black

.details
  +vSpace(.6rem)
  li
    position: relative
    padding-left: 2rem
    &::before
      content: "•"
      position: absolute
      top: 0
      left: 0
      width: 2rem
      text-align: center
      
.bottom
  padding: 1.2rem 1.2rem 0
  +vSpace(.8rem)
  border-top: 1px solid $stroke-color
  margin-left: -1.2rem
  margin-right: -1.2rem
  display: flex
  flex-direction: column
  +res-md-min
    min-height: 17.4rem

.prices
  display: flex
  flex-wrap: wrap
  align-items: flex-end
  gap: .4rem .8rem 

.price
  &--current
    +typoBase(2.2rem, 2.9rem, $fw-bold)
    color: $text-black
  &--listino, &--prev
    .priceValue
      text-decoration: line-through
  &--listino
    width: 100%


.noBuy
  +vSpace(.4rem)
  +typoTextSm
  a
    font-weight: $fw-semibold
    text-decoration: underline
    color: $text-black

.size
  display: flex
  align-items: center
  gap: 2rem
  > *
    width: calc(50% - 1rem)

.sizeLabel
  color: $text-black

.cta
  width: 100%
  margin-top: auto

.variantsModal
  max-width: 100%
  max-height: 100%
  width: 100%
  bottom: 0
  top: auto
  padding: 3.4rem 2rem 2rem
  background: $background-light
  +vSpace(2rem)
  &::backdrop
    background: rgba($fill-black-r, $fill-black-g, $fill-black-b, .6)
  +res-md-min
    display: none

.variantModalCloseBar
  position: absolute
  top: 0
  left: 0

.variantsModalHead
  display: flex
  align-items: flex-start
  justify-content: space-between
  gap: 2rem

.variantsModalTitle
  +typoH5

.variantsModalClose
  padding-to: 1px
  svg
    +styleIcon(2.4rem, $fill-black)

.variantsModalList
  +vSpace(2rem)

.variantsModalFooter
  text-align: center

.variantsModalCta
  +res-xs-max
    width: 100%

  
