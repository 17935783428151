@import styles/sass/abstracts

.root
    padding: 2.4rem 0
    background: $fill-secondary-90
    color: $text-white
    position: fixed
    bottom: 0
    width: 100%
    z-index: 2  // deve stare sopra lo slider dei plus point
    transform: translateY(100%)
    transition: transform .3s ease-in
    +res-lg-max
        display: none
    &--visible
        transform: translateY(0)
    @media print
        display: none

.main
    display: flex
    align-items: center
    justify-content: space-between

.infos
    display: flex

.title
    +typoH5
    font-weight: $fw-semibold

.prices
    +typoTextSm
    display: flex
    gap: 1rem
    margin-right: 1.6rem
    align-items: flex-end

.price
    display: block
    &--current
        +typoH4
    &--listino, &--prev
        +typoTextSm
        font-family: $ff-secondary
        .priceValue
            text-decoration: line-through

.ctaBox
    gap: 1.6rem
    display: flex
    svg
        +colorIcon($text-white)
