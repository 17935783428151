@import styles/sass/abstracts

.root
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  width: 100vw
  background: none
  max-width: calc(170vh - 17rem)
  padding: 2rem
  +res-xl-min
    width: 115rem

.layout
  position: relative
  padding-top: 4rem

.close
  position: absolute
  top: 0
  right: 0
  &:focus-visible
    outline-color: $fill-white
  svg
    +styleIcon(2.4rem, $fill-white)

.iframe
  background: #000
