@import styles/sass/abstracts

.root
  padding: 1.2rem
  border: 1px solid $stroke-color
  display: flex
  align-items: center
  gap: 1.6rem
  +typoTextSm
  &--success
    border-color: $fill-success
    background: $fill-success-10
    .icon
      background: $fill-success
  &--error
    border-color: $fill-error
    background: $fill-error-10
    .icon
      background: $fill-error 

.icon
  padding: .6rem
  border-radius: 50%
  +res-sm-max
    padding: .2rem
  svg
    +styleIcon(2rem, $fill-white)

.title
  font-weight: $fw-semibold
