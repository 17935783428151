@import styles/sass/abstracts

.root
  display: block
  +typoTextMd
  color: $text-grey-dark
  border-bottom: 2px solid transparent
  padding: 1.6rem 0 1.4rem
  transition: border-color .3s, color .3s
  &:focus-visible
    outline-offset: -2px 
  &--selected
    font-weight: $fw-semibold
    color: $text-black
    border-bottom-color: $border-black
  &:hover
    text-decoration: underline
    color: $text-black

