@import styles/sass/abstracts

.root
  padding: 8rem 0
  color: $text-black
  +borderer
  +res-sm-max
    padding: 4rem 0
  +spacer
  &--primary, &--secondarydark
    color: $text-white
    .description
      +customContentDarkVariant
  &--secondarydark
    background: $fill-secondary-90
  &--primary
    background: $fill-primary-70
  &--neutrallight
    background: $fill-neutral-30
  
  +res-md-min
    \:global(.block-card-group--iconTextSectionButtons)
      justify-content: center
  \:global(.block-card-group--empty)
    &:global(.block-card-group--iconTextSectionButtons)
      &::before
        content: "Trascina qui gli elementi Button"
    &:global(.block-card-group--iconTextSectionCards)
      &::before
        content: "Trascina qui gli elementi Icon text card"

.layout
  +vSpace(4rem, 2rem)

.main
  +vSpace(2rem)
  +res-md-min
    text-align: center

.title
  +typoH2
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd

.ctaBox, .root :global(.block-card-group--iconTextSectionButtons)
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 1.2rem

.ctaBox
  +res-md-min
    justify-content: center

.list, .root :global(.block-card-group--iconTextSectionCards)
  +res-md-min
    display: flex
    flex-wrap: wrap
    gap: 2rem
    > *
      width: calc(50% - 1rem)
  +res-sm-max
    +vSpace(2rem)

.list.list--n3, .root :global(.block-card-group--iconTextSectionCards):global(.block-card-group--n3)
  +res-md-min
    > *
      width: calc(33.33% - 1.3333rem)
