.nibbi-theme
  --stroke-color: #CEC4B4 
  --background-light: #FFFDF9
  --background-light-r: 255
  --background-light-g: 253
  --background-light-b: 249
  --text-black: #161616
  --text-grey-dark: #636A64
  --text-white: #fff
  --text-primary-70: #D71A1A
  --text-primary-80: #A80404
  --text-idee-verdi: #0D6C38
  --text-disabled: #CEC4B4
  --text-error: #D10000
  --text-batteria: #006F9E
  --fill-black: #161616
  --fill-black-r: 22
  --fill-black-g: 22
  --fill-black-b: 22
  --fill-grey-dark: #636A64
  --fill-white: #FFFFFF
  --fill-primary-70: #D71A1A
  --fill-primary-80: #A80404
  --fill-primary-40: #E97676
  --fill-primary-10: #FFE9E9 
  --fill-secondary-90: #2E2623
  --fill-secondary-20: #C7BBB7
  --fill-neutral-30: #F3E9D8
  --fill-neutral-20: #FCF6EC
  --fill-neutral-20-r: 252
  --fill-neutral-20-g: 246
  --fill-neutral-20-b: 236
  --fill-idee-verdi: #0D6C38
  --fill-success: #005229
  --fill-success-10: #E2E9D8
  --fill-error: #D10000
  --fill-error-10: #FFEBEB
  --fill-batteria: #006F9E
  --fill-accent-1: #BEEF7D
  --fill-disabled: #CEC4B4
  --border-primary-70: #D71A1A
  --border-black: #161616
