@import styles/sass/abstracts

.root
  display: flex
  align-items: flex-start
  gap: 1.6rem
  &:not(.root--omaggio)
    .details
      align-self: stretch
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: .8rem

.imageBox
  display: block
  width: 6.4rem

.main
  flex: 1
  +vSpace(.8rem)
    
.category
  display: block
  +typoTextXs
  text-transform: uppercase
  color: $text-grey-dark

.title
  display: block
  +typoTextSm
  font-weight: $fw-semibold

.bottom
  display: flex
  align-items: flex-end
  justify-content: space-between
  gap: .8rem

.attributes
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: .4rem 1.6rem

.attribute
  +typoTextXs

.attributeValue
  font-weight: $fw-semibold

.removeButton
  margin-top: auto

.pricesBox
  +vSpace(.4rem)
  text-align: right

.prices
  +vSpace(.4rem)
  +typoTextXs

.price
  white-space: nowrap
  &:not(.price--current)
    color: $text-grey-dark
    .priceValue
      text-decoration: line-through
  &--current
    +typoTextSm
    font-weight: $fw-semibold
    display: inline-flex // se c'è "prezzo lancio" meglio mandare a capo label e value altrimenti a risoluzioni basse non ci sta
    flex-wrap: wrap
    justify-content: flex-end
    gap: 0 .4rem

