@import styles/sass/abstracts

.root
  padding: 2rem
  background: $fill-primary-10
  color: $text-black
  display: flex
  align-items: flex-start
  gap: 1.6rem

.main
  +vSpace(1.6rem)

.title
  +typoH6

.icon
  svg 
    +styleIcon(2.4rem, $fill-black)