@import styles/sass/abstracts

.root
  background-color: $fill-neutral-20
  padding: 2.4rem
  +vSpace(2.4rem)
  +res-sm-max
    padding: 1.6rem

.title
  +typoH5
  +res-sm-max
    +typoH6Mobile

.content
  +res-sm-min
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    gap: 2.4rem
  +res-xs-max
    +vSpace(2.4rem)

.info
  flex: 1
  display: flex
  gap: 1.2rem
  +typoTextSm
  +res-md-min
    > *
      margin: auto 0
    
.item
  min-width: 13rem
  &--stato 
    +res-xs-max
      display: none

.key, .value
  display: block

.key
  font-weight: 600

.images
  display: flex
  align-items: center
  gap: 1.6rem
  +res-sm-min
    margin-left: auto

.imageBox
  width: 6.4rem
  aspect-ratio: 1
  +res-md-max
    width: 5.4rem
  +res-xs-max
    flex: 1
    width: auto
    max-width: 5.4rem
  
  &--nImages
    background-color: $fill-neutral-30
    position: relative
    display: flex
    align-items: center
    justify-content: center
    +typoTextLg
    +res-xs-max
      border: 1px solid $stroke-color
      background: none
    
  

.buttons
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  +res-sm-max
    width: 100%

.detailsButton
  +res-sm-min
    margin-left: auto
  +res-xs-max
    width: 100%

.trackButton
  +res-xs-max
    display: none