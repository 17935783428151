@import 'styles/sass/abstracts'

.root
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding

  &--md
    max-width: $wcont-width-md
  &--sm
    max-width: $wcont-width-sm
  &--sm-plus
    max-width: $wcont-width-sm-plus
  &--xs
    max-width: $wcont-width-xs
  &--blog-head
    max-width: $wcont-width-blog-head
  &--blog-content
    max-width: $wcont-width-blog-content

  @media print
    padding-left: 0
    padding-right: 0
