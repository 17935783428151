@import styles/sass/abstracts

.root
  color: $text-black

.layout
  +vSpace(4rem, 2rem)

.main
  +vSpace(2.4rem, 2rem)
  text-align: center

.title
  +typoH1
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextXl
  +customContent
  +res-sm-max
    +typoTextMd


.imageBox
  img
    height: auto