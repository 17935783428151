@import styles/sass/abstracts

.root
  display: flex
  align-items: flex-start
  gap: 1.6rem

.imageBox
  width: 6.4rem

.main
  flex: 1
  +vSpace(.8rem)
    
.category
  display: block
  +typoTextXs
  text-transform: uppercase
  color: $text-grey-dark

.title
  +typoTextLg
  font-weight: $fw-semibold

.attributes
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: .4rem 1.6rem

.attribute
  +typoTextXs

.attributeValue
  font-weight: $fw-semibold

.prices
  display: flex
  flex-wrap: wrap
  align-items: flex-end
  gap: .8rem
  +typoTextXs

.price
  white-space: nowrap
  &:not(.price--current)
    color: $text-grey-dark
    text-decoration: line-through
  &--current
    +typoTextLg
    font-weight: $fw-semibold
