@import styles/sass/abstracts

.root
  display: flex
  align-items: center
  gap: 2rem
  padding: 3.2rem
  color: $text-black
  border: 1px solid $stroke-color
  +res-sm-max
    padding: 2rem

.main
  flex: 1
  +vSpace(.8rem)

.imageBox
  width: 10rem
  height: 10rem
  +res-sm-max
    width: 6rem
    height: 6rem


.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.num
  +typoTextMd
  color: $text-grey-dark
  +res-sm-max
    +typoTextSm
