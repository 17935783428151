@import styles/sass/abstracts

.root
  color: $text-black
  position: fixed
  width: 100%
  max-height: 100dvh
  background: $background-light
  z-index: 3002
  padding: 4rem 0
  top: 0
  left: 0
  display: none
  overflow-y: auto
  +scrollbarStyle
  +res-menu-mobile-max
    position: fixed
    padding: 2rem 0
    top: $header-main-height-mobile
    border-top: 1px solid $stroke-color
    max-height: calc(100dvh - $header-main-height-mobile)
  &--open
    display: block

.overlay
  background: rgba(#000, .5)
  position: fixed
  height: 100vh
  width: 100%
  top: 0
  left: 0
  z-index: 3001
  +res-menu-mobile-max
    top: $header-main-height-mobile

.layout
  +res-menu-desktop-min
    display: flex
    gap: 2.4rem
    justify-content: space-between
    > *
      flex: 1
      max-width: 54rem
  +res-menu-mobile-max
    +vSpace(2rem)

.leftLayout
  +vSpace(2rem)
  +res-menu-desktop-min

.suggestions
  +vSpace(.9rem)
  +typoTextSm
  > *
    display: block

.rightLayout
  +vSpace(4rem, 2rem)

.titleContainer
  position: relative

.title
  +typoH5

.close
  position: absolute
  top: 0
  right: 0
  svg
    +styleIcon(2.4rem, $fill-black)

.subTitle
  +typoTextXs
  color: $text-grey-dark
  text-transform: uppercase
  font-weight: $fw-semibold
  margin-bottom: 1.6rem

.frequentiList
  display: flex
  gap: 0.9rem
  flex-wrap: wrap
  margin-bottom: 2rem

.titleContainer
  padding-right: 3.2rem
  margin-bottom: 2rem

.borderMobile
  +res-menu-mobile-max
    border-top: 1px solid $stroke-color

.productList
  +res-menu-mobile-max
    border-top: 1px solid $stroke-color
    padding: 2rem 0 0 0


\:global(.toolbar ~ .page)
  .root, .overlay
    +res-menu-desktop-min
      top: $toolbar-height
    +res-menu-mobile-max
      top: #{$header-height-mobile + $toolbar-height}
  .root
    max-height: calc(100dvh - $toolbar-height)
    +res-menu-mobile-max
      max-height: calc(100dvh - #{$header-height-mobile + $toolbar-height})


\:global(.preheader ~ .page)
  .root, .overlay
    +res-menu-mobile-max
      top: #{$header-main-height-mobile + $preheader-height-mobile}
  .root
    +res-menu-mobile-max
      max-height: calc(100dvh - #{$header-main-height-mobile + $preheader-height-mobile})


\:global(.toolbar ~ .preheader ~ .page)
  .root, .overlay
    +res-menu-mobile-max
      top: #{$header-main-height-mobile + $preheader-height-mobile + $toolbar-height}
  .root
    +res-menu-mobile-max
      max-height: calc(100dvh - #{$header-main-height-mobile + $preheader-height-mobile + $toolbar-height})
