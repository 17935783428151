@import "../../styles/sass/abstracts"

.block-ricerca-prodotto-card-section
  > *
    position: relative
    &:not(:last-child)
      margin-bottom: 8.1rem
      &::after
        position: absolute
        bottom: -4.1rem
        left: 0
        width: 100%
        content: ""
        display: block
        border-bottom: 1px solid $stroke-color
      +res-sm-max
        margin-bottom: 4.1rem
        &::after
          bottom: -2.1rem
