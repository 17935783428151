@import styles/sass/abstracts

.root
  padding: 2rem
  background: $fill-white
  color: $text-black
  +vSpace(1.6rem)

.title
  // +typoH5  commentato temporaneamente per approvazione per far stare su una riga i titoli lunghi, poi vediamo soluzione ottimale
  +typoH6

.imageBox
  display: flex
  justify-content: center
  height: 15.7rem