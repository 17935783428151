@import styles/sass/abstracts

.root
  \:global(.swiper-slide)
    box-sizing: border-box
    height: auto
    &:not(:first-child)
      .item
        border-left: none

.item
  height: 100%
    