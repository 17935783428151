.pagebuilder-import-page
    padding: 0 10px
    text-align: center
    &__title
        +typoTextLg
        font-weight: 600
        margin-bottom: 24px
    &__options
        display: flex
        justify-content: center
        margin-bottom: 24px
        > *
            flex: 1 0 0
            &:not(:last-child)
                margin-right: 20px
    &__option
        padding: 24px 16px
        border-radius: 4px
        border: 1px solid $pb-grey-20
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        +typoTextMd
        font-weight: 600
        color: $pb-grey-100
        max-width: 170px
        cursor: pointer
        &:hover
            color: $pb-black
            border-color: $pb-grey-50
        svg
            +style-icon(60px, currentColor)
            margin-bottom: 10px

    &__step2
        position: relative
        &__back
            position: absolute
            top: -8px
            left: 0


    &__file
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        height: calc(100vh - 264px)
        &__error
            +typoTextSm
            color: $pb-error-default
            margin-top: 8px
            
        &__title
            +typoTextMd
            font-weight: 600
            margin-bottom: 8px
            margin-top: 20px
        &__preview
            position: relative
            border: 1px solid $pb-grey-20
            border-radius: 2px
            align-self: stretch
            flex: 1 1 auto
            min-height: 100px
            &__editor
                position: absolute
                top: 0
                left: 0
                width: 200%
                height: 200%
                overflow-y: auto
                overflow-x: hidden
                transform: scale(.5)
                transform-origin: top left
                // Scrollbar doppia perchè è resizato della metà
                +scrollbar-style
                &::-webkit-scrollbar
                    width: 12px
                &::-webkit-scrollbar-thumb
                    border-radius: 6px
                &__content
                    pointer-events: none

        &__cta-box
            margin-top: 30px

    .placeholder-listing
        display: flex
        flex-wrap: wrap
        justify-content: center
        margin: 0 -10px
        > *
            margin: 0 10px 20px
            width: 180px
            &:not(:nth-child(3n))
                margin-right: 20px

    .placeholder-listing-item
        padding: 10px
        border: 1px solid $pb-grey-20
        border-radius: 2px
        cursor: pointer
        &:hover
            border-color: $pb-grey-50
            background: $pb-grey-10
        &__title
            +typoTextSm
            font-weight: 600
            margin-bottom: 8px
            span
                display: block
                text-align: center
                width: 100%
                white-space: nowrap
                text-overflow: ellipsis
                overflow-x: hidden
                &:last-child
                    text-transform: uppercase
        &__editor
            border: 1px solid $pb-grey-10
            height: 120px
            background: $pb-white
            overflow: hidden
            pointer-events: none
            > *
                transform: scale(.1)
                transform-origin: top left
                width: 1000%


    &__list
        display: none
        // display: flex
        flex-wrap: wrap
        margin-bottom: 24px
        > *
            flex: 1 0 0
            &:not(:last-child)
                margin-right: 20px
    &__item
        padding: 24px 16px
        border-radius: 4px
        border: 1px solid $pb-grey-20
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 20px
        &--full
            flex: 1 0 100%
            margin-right: 0
        &__title
            +typoTextMd
            font-weight: 600
        &__content
            margin-top: 20px
        &__error
            +typoTextSm
            margin-top: 4px
            color: $pb-error-default


.pagebuilder-import-page-close
    width: 100%
    display: flex
    justify-content: flex-end
    padding: 20px 20px 0 0
    a
        cursor: pointer

.pagebuilder-wrapper
    .pb-modal
        max-width: 800px
        &__close
            top: 16px
            right: 16px