@import styles/sass/abstracts

.bloccoIndirizzi
  &:not(:last-child)
    padding-top: 1.6rem
    margin-bottom: 4rem
    padding-bottom: 4rem
    border-bottom: 1px solid $stroke-color
    +res-sm-max
      padding-top: 0rem
      margin-bottom: 2.4rem
      padding-bottom: 2.4rem
  > *
    &:not(:first-child)
      margin-top: 1.6rem

.title
  +typoH6

.confirmDeleteModal
  max-width: 48.4rem

.confirmDeleteModalCtaBox
  display: flex
  align-items: center
  justify-content: center
  gap: 1.6rem
  > *
    min-width: 13rem