@import styles/sass/abstracts

.root
  \:global(.swiper)
    padding: 0 $wcont-padding
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
  \:global(.swiper-slide)
    width: auto!important
    &:not(:first-child)
      .item
        border-left: none