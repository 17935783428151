@import 'styles/sass/abstracts'
  
.root
  position: relative
  display: block
  cursor: pointer
  color: $text-black
  +typoTextMd

.containerLabel
  display: flex
  align-items: center
  gap: .8rem

.inputWrap
  display: flex
  width: fit-content
  align-items: center
  gap: .8rem
  &--yesnofull
    width: 100%
    justify-content: space-between

.label    
  display: block

.input
  position: absolute
  left: -9999px

  &:not(:disabled)
    &:focus-visible
      +.styledInput
        +focusVisibleStd

.styledInput
  position: relative
  display: block
  background-color: $fill-grey-dark
  transition: background-color .2s linear
  width: 3.5rem
  height: 2rem
  border-radius: 2.4rem
  &::before
    content: ""
    position: absolute
    top: .3rem
    left: .3rem
    width: 1.4rem
    height: 1.4rem
    display: block
    border-radius: 50%
    background: $fill-white
    transition: left .2s linear

.icon
  display: block
  svg
    +styleIcon(2.4rem, currentColor)

.helper
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: 0

.root
  // Checked
  &--checked
    .styledInput
      background: $fill-primary-70
      &::before
        left: 50%
  
  &:not(.root--disabled)
    +res-md-min
      .inputWrap
        cursor: pointer

  // // Disabled
  // &--disabled
  //   opacity: .3
  
  // // Status
  // &--error
  //   .input
  //     &+ .styledInput
  //       color: $error-70
