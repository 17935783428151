@import styles/sass/abstracts

.root
  display: inline-flex
  justify-content: center
  gap: 1.2rem
  color: $text-black
  border: 1px solid $fill-black
  padding: 1.2rem .6rem
  border-radius: 2.4rem

.label
  +typoBase(1.6rem, 1.5, $fw-bold, $ff-secondary)

.icon
  svg
    +styleIcon(2.4rem, $fill-black)