.react-select
  &--menu-top
    .react-select
      &__menu
        bottom: 100%!important
        top: auto!important
        margin: 0 0 .8rem!important
        
  &--in-slider
    .react-select
      &__menu
        display: none
    &.react-select--open
      .react-select
        &__menu
          display: block


  &__control
    width: 100%
    border: 1px solid $stroke-color!important
    border-radius: .8rem!important
    background: $fill-white!important
    color: $fill-grey-dark!important
    outline: none!important
    +typoTextMd
    padding: .7rem 1.1rem!important
    transition: border-color .2s, background-color .2s, color .2s, box-shadow .2s!important
    min-height: 0!important
    cursor: pointer!important
    &--is-focused
      border-color: $fill-black!important
      box-shadow: 0 0 0 1px $fill-black!important

    &--menu-is-open
      .react-select__dropdown-indicator
        svg
          transform: rotate(180deg)

  &__placeholder
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  &__indicator-separator
    display: none!important

  &__dropdown-indicator 
    svg
      +styleIcon(2.4rem, $fill-black)

      
  &__value-container
    padding: 0!important
    min-height: 2.4rem

  &__single-value
    margin: 0!important
    color: $fill-black!important

  &__input-container
    padding: 0!important
    margin: 0!important

  &__menu-notice
    font-size: 1.2rem!important

  &__menu
    margin: .8rem 0 0!important
    border-radius: .8rem!important
    box-shadow: none!important
    overflow: hidden!important
    border: 1px solid $stroke-color!important
    z-index: 2!important

  &__menu-list
    padding: 0!important
    +scrollbarStyle

  &__option
    position: relative!important
    font-size: 1.4rem!important
    line-height: 1.5!important
    padding: 1.2rem!important
    color: $text-black!important
    cursor: pointer!important
    background: $fill-white!important
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color!important
    &--is-selected
      font-weight: $fw-semibold!important
    &:hover
      background: $fill-neutral-20!important
    
  

  // Disabled
  &--is-disabled
    .react-select
      &__control
        background: $fill-neutral-30!important
        cursor: default!important
      &__single-value
        color: $text-grey-dark!important
      &__dropdown-indicator
        svg
          color: $text-grey-dark!important

  // Status
  &--error
    .react-select
      &__control
        border-color: $fill-error!important
        &--is-focused
          box-shadow: 0 0 0 1px $fill-error!important


  &--dropdown
    .react-select
      &__control
        border-color: $fill-grey-dark!important