@import styles/sass/abstracts

.layout
  +vSpace(4rem, 1.6rem)

.description
  +typoTextMd
  text-align: center
  +customContent
  +res-md-max
    text-align: left

.form
  +vSpace(4rem, 2.4rem)

.ctaBox
  text-align: center
