@import styles/sass/abstracts

.root
  padding: 8rem 0 
  background: $fill-neutral-30
  color: $text-black
  +res-sm-max
    padding: 4rem 0
  &--primary
    background: $fill-primary-70
  &--secondarydark
    background: $fill-secondary-90
  &--primary, &--secondarydark
    color: $text-white
    .description
      +customContentDarkVariant

.layout
  +vSpace(4rem, 2rem)

.main
  +vSpace(2rem)
  text-align: center
  max-width: 86rem
  margin-left: auto
  margin-right: auto

.title
  +typoH2
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd

.slider
  \:global(.swiper)
    +widen($wcont-padding)
  \:global(.swiper-slide)
    +res-lg-max
      width: 25rem!important
  \:global(.swiper-pagination)
    +res-sm-max
      margin-top: 2rem

.ctaBox
  text-align: center
