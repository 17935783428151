@import styles/sass/abstracts

.root
  padding: 2rem 0

.layout
  +res-lg-min
    display: flex
    align-items: center
    gap: 4rem
    > *
      width: calc(50% - 2rem)
  +res-md-max
    +vSpace(2rem)

.main
  +vSpace(2rem)

.content
  +vSpace(1.2rem)

.title
  +typoH2
  +res-sm-max
    +typoH3

.description
  +typoTextLg
  +res-sm-max
    +typoTextMd

.list
  display: flex
  flex-wrap: wrap
  gap: 2rem
  > *
    width: calc(50% - 1rem)
    &:first-child
      width: 100%
  +res-sm-max
    display: none

.slider
  +res-md-min
    display: none
  \:global(.swiper)
    +widen($wcont-padding)
