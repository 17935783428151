@import styles/sass/abstracts

.root
  &--openResults
    .mapListBox
      +res-md-min
        display: block
    .map
      +res-md-min
        padding-left: 40rem

.layout
  +vSpace(4rem, 2rem)

.head
  +vSpace(2.4rem)

.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.form
  display: flex
  align-items: flex-end
  gap: 1.6rem
  position: relative
  z-index: 3
  +res-xs-max
    flex-wrap: wrap

.formField
  +res-sm-min
    max-width: 31.5rem

.submitDesktop
  +res-xs-max
    display: none

.submitMobile
  width: 100%
  +res-sm-min
    display: none

.mapLayout
  position: relative
  +res-sm-max
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}

.map
  height: 60rem
  background: #ccc
  +res-sm-max
    height: 45rem

.mapNav
  display: flex
  +res-md-min
    display: none
  > *
    width: 50%
    +typoTextMd
    padding: 1rem
    border: 1px solid $stroke-color
    &:focus-visible
      outline-offset: -2px
    &:disabled
      font-weight: $fw-semibold
      background: $fill-black
      border-color: $fill-black
      color: $text-white
    &:not(:disabled)
      text-decoration: underline
  &--hidden
    display: none

.mapListBox
  overflow-y: auto
  +scrollbarStyle
  +res-md-min
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 40rem
    border: 1px solid $stroke-color
    background: $background-light
    z-index: 2
    display: none

.mapEmptyList
  padding: 4rem 2rem
  text-align: center
  +typoTextLg

.mapList
  +res-sm-max
    border-top: 0
    border-bottom: 0
  > *
    border-bottom: 1px solid $stroke-color
    +res-sm-max
      &:last-child
        border-bottom: none

.mapListMoreBox
  padding: 2rem $wcont-padding
  +res-md-min
    display: none

.mapListMore
  width: 100%

.mapNavItem, .mapItem
  &--hidden
    +res-sm-max
      display: none
