@import "../../styles/sass/abstracts"

.ROOT
  &--editing
    .block-w-container
      min-height: 4rem


.block-w-container
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  +spacer
  +vSpace(4rem, 2rem)

  &--md
    max-width: $wcont-width-md

  &--sm
    max-width: $wcont-width-sm

  &--xs
    max-width: $wcont-width-xs

  &--blog-content
    max-width: $wcont-width-blog-content

  
