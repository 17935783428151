@import styles/sass/abstracts

.root
  position: relative
  height: 50rem
  color: $text-white
  &--half
    .layout
      max-width: 100%
      left: 0
  &:not(.root--half)
    .layout
      @media screen and (min-width: #{$wcont-width-md-px + 112px})
        left: calc(50vw - #{$wcont-width-md * .5 + 7.2rem - $wcont-padding})

.imageBox
  height: 100%
  &::after
    content: ""
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 22.59%, rgba(0, 0, 0, 0.8) 81.7%)
    z-index: 1

.image
  &--desktop
    +res-md-max
      display: none
  &--tablet
    +res-lg-min
      display: none
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none


.layout
  max-width: $wcont-width-md
  position: absolute
  left: 0
  width: 100%
  bottom: 4rem
  z-index: 2

.main
  +vSpace(1.6rem)
  padding: 0 $wcont-padding
  +res-md-min
    padding-left: 7.2rem
    padding-right: 7.2rem
    max-width: 75.6rem
  +res-sm-max
    text-align: center

.title
  +typoH2
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextLg
  +customContentDarkVariant
