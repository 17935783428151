@import styles/sass/abstracts

.root
  +maxResBounds
  &--editMode
    padding: 2rem 0
  > :global(.block-card-group)
    padding: 2rem 0
    +vSpace(4rem)
  > :global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi Home hero block"

  \:global(.block-home-hero-block)
    padding: 2rem 0
    \:global(.block-card-group--empty)
      &::before
        content: "Trascina qui gli elementi Home hero slide"
    > *
      display: flex
      > *
        flex: 1

.slider
  &--desktop
    +res-md-max
      display: none
  &--mobile
    +res-lg-min
      display: none

.item
  &--half
    display: flex
    width: 100%
    > *
      &:not(:first-child)
        flex: 1
      &:first-child
        width: 57.5%
      &:not(:last-child)
        position: relative
        &::after
          content: ""
          position: absolute
          top: 0
          height: 100%
          width: 1px
          right: 0
          background: $stroke-color
