@import 'styles/sass/abstracts'
  
.root
  position: relative
  display: block
  cursor: pointer
  color: $text-black
  min-height: 2.4rem
  +typoTextSm
  &:not(.root--disabled)
    .inputWrap
      cursor: pointer
  &--disabled
    color: $text-grey-dark
  &:hover
    .input:checked:not(:disabled)
      &+ .styledInput
        svg
          color: $fill-black

.inputWrap
  display: block

.label    
  display: block
  padding-left: 3.2rem
  user-select: none
  line-height: 2.4rem
  a
    +typoLink

.input
  position: absolute
  left: -9999px

  &+ .styledInput
    position: absolute
    left: 0
    top: 0
    svg
      transform: all .3s
      +styleIcon(2.4rem, $fill-black)

  &:focus-visible
    &+ .styledInput
      +focusVisibleStd
      outline-offset: -2px

  &:checked:not(:disabled)
    &+ .styledInput
      svg
        color: $fill-primary-70
      
  &:disabled
    &+ .styledInput
      svg
        +colorIcon($fill-grey-dark)
  
.error
  margin-top: 1.6rem