@import styles/sass/abstracts

.root
  padding: 3.2rem
  border: 1px solid $stroke-color
  +res-sm-max
    padding: 2.4rem $wcont-padding
    border-left: none
    border-right: none
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
  &--closed
    .head
      opacity: .5
  &:not(.root--open)
    .openBody
      display: none
  &:not(.root--completed)
    .completedBody
      display: none

  &--noHeadMobile
    +res-sm-max
      .head
        display: none
      .openBody
        margin-top: 0
        padding-top: 0
        border-top: none

.head
  display: flex
  align-items: center
  gap: .8rem

.completedIcon
  svg
    +styleIcon(2.4rem, $fill-success, $fill-white)

.title
  +typoH5

.step
  +typoTextMd
  margin-left: auto

.openBody
  border-top: 1px solid $stroke-color
  padding-top: 2rem
  margin-top: 2rem
  +vSpace(2rem)

.description
  color: $text-grey-dark 
  +typoTextSm
  +customContent

.completedBody
  margin-top: 1.6rem
  display: flex
  align-items: center
  gap: 1.6rem

.completedContent
  +vSpace(.4rem)
  +typoTextSm

.completedTitle
  font-weight: $fw-semibold

.edit
  margin-left: auto