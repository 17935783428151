@import styles/sass/abstracts

.root
  color: $text-black
  display: flex
  align-items: flex-start
  gap: 2rem
  background: $fill-neutral-20
  padding: 1rem
  border: 1px solid $fill-neutral-20
  +res-sm-max
    gap: 1.6rem
  &--transparent
    background: none
    border-color: $fill-neutral-30

.imageBox
  width: 6.4rem

.main
  flex: 1
  +res-md-min
    display: flex
    align-items: flex-end
    gap: 1.6rem
  +res-sm-max
    +vSpace(.8rem)

.content
  +vSpace(.8rem)
  flex: 1

.category
  display: block
  +typoTextXs
  text-transform: uppercase
  color: $text-grey-dark
  font-weight: $fw-medium

.title
  +typoTextSm
  font-weight: $fw-semibold

.attributes
  display: flex
  flex-wrap: wrap
  gap: .8rem 1.6rem

.attribute
  +typoTextXs

.attributeValue
  font-weight: $fw-semibold

.pricesBox
  +vSpace(.4rem)
  +res-md-min
    text-align: right

.prices
  +vSpace(.4rem)
  +typoTextXs

.price
  white-space: nowrap
  &:not(.price--current)
    color: $text-grey-dark
    .priceValue
      text-decoration: line-through
  &--current
    +typoTextSm
    font-weight: $fw-semibold
