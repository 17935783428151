@import styles/sass/abstracts

.root
  +vSpace(2.4rem, 2rem)
  color: $text-black

.main
  +vSpace(.8rem)

.title
  +typoH5
  +res-sm-max
    +typoH6Mobile

.description 
  +typoTextSm
  
.tableWrap
  color: $text-grey-dark
  overflow-x: auto
  +horizontalScrollbarStyle
  // Se è touch nascondo la scrollbar
  @media (hover: none)
    &::-webkit-scrollbar
      height: 0
    +widen($wcont-padding)
    +res-lg-min 
      margin-left: -4rem
      padding-left: 4rem
  table
    border: 1px solid $stroke-color
    border-collapse: collapse
    width: 100%
    +typoTextSm
    white-space: nowrap
  th
    font-weight: $fw-semibold
    color: $text-black
  td, th
    padding: 1.6rem .95rem
    min-width: 4.2rem
    text-align: center
    &:not(:last-child)
      border-right: 1px solid $stroke-color
  th
    &:first-child
      text-align: left
  tr:not(:last-child)
    td, th
      border-bottom: 1px solid $stroke-color
  thead:not(:last-child)
    tr
      td, th
        border-bottom: 1px solid $stroke-color
  