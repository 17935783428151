.pb-form-select 

  &__icon-option
    display: flex
    align-items: center
  
    &__icon
      svg
        margin-right: 1.2rem
        +styleIcon(2.4rem, currentColor)

  // Status
  &--error
    .pb-react-select
      &__control
        border-color: $pb-error-default!important
        box-shadow: 0 0 0 1px $pb-error-default!important

.pb-react-select

  &__control
    color: $pb-grey-100
    +typoTextSm
    font-family: $pb-font-primary
    +placeholder-style
      color: $pb-grey-80
    width: 100%
    border: 1px solid $pb-grey-50!important
    min-height: 3rem!important
    height: 3rem

  &__indicator-separator
    display: none!important

  &__value-container
    padding: 0 0.8rem!important
    min-height: 3rem

  &__indicators
    min-height: 3rem
  
  &__indicator
    padding: 0 0.8rem!important

  &__menu-portal
    z-index: 9999!important

  &__menu
    min-width: 100%
    width: auto!important
    right: 0!important

  &__menu-list
    color: $pb-grey-100
    +typoTextSm
    font-family: $pb-font-primary
    +placeholder-style
      color: $pb-grey-80
    +scrollbar-style

  &__option
    white-space: nowrap