@import styles/sass/abstracts

.root
  width: fit-content
  padding: .4rem .9rem
  +typoTextSm
  background: $background-light
  color: $text-black
  border: 1px solid $stroke-color
  white-space: nowrap
  &--sm
    padding: .3rem .7rem
    +typoTextXs