@import 'styles/sass/abstracts'

.inputWrap
  position: relative
  
.input
  width: 100%
  border: 1px solid $stroke-color
  border-radius: .8rem
  background: $fill-white
  color: $text-black
  outline: none
  +typoTextMd
  font-family: $ff-primary
  padding: .7rem 1.1rem
  transition: border-color .2s, box-shadow .2s, background-color .2s
  box-shadow: 0 0 0 0 rgba($fill-black, 0)
  +placeholderStyle
    color: $text-grey-dark
  &:focus
    border-color: $fill-black
    box-shadow: 0 0 0 1px $fill-black
  &:disabled
    background: $fill-neutral-30
    box-shadow: none
    color: $text-grey-dark

.toggle
  position: absolute
  top: calc(50% - 1.2rem)
  right: 1.1rem
  svg
    +styleIcon(2.4rem, $fill-black)

.helper
  +typoTextXs
  color: $text-black
  margin-top: .4rem

.root
  // Status
  &--error
    .input
      border-color: $fill-error
      box-shadow: none
      padding-right: 4.5rem
    .alertIcon
      display: block
    