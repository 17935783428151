@import styles/sass/abstracts

.root
  position: relative
  display: flex
  align-items: center
  gap: .4rem
  +typoTextMd
  font-weight: $fw-medium
  &--green
    color: $text-idee-verdi

  &--sm
    +typoTextSm

  &--notification
    .icon
      position: relative
      &::after
        content: ""
        position: absolute
        width: .8rem
        height: .8rem
        border-radius: 50%
        top: 0
        right: 0
        background: $fill-primary-70
        +res-md-max
          width: .5rem
          height: .5rem
          right: .4rem
          top: .4rem

  &--icon
    border-radius: 50%
    transition: background .3s
    &:hover
      background: $fill-neutral-30
    .icon
      padding: .4rem
      svg
        +sizeIcon(2.4rem)
        

  &:not(.root--icon)
    position: relative
    &::after
      content: ""
      position: absolute
      bottom: 0
      height: 1px
      left: 0
      width: 100%
      background: currentColor
      opacity: 0
      transition: opacity .2s
    &:hover
      &::after
        opacity: 1
      

.icon
  display: block
  svg
    display: block
    +sizeIcon(2rem)

.badge
  position: absolute
  top: 0
  right: 0
  width: 1.4rem
  height: 1.4rem
  border-radius: 50%
  background: $fill-primary-70
  +typoBase(.9rem, 1.4rem)
  color: $text-white
  text-align: center