@import styles/sass/abstracts

.root
  color: $text-black
  background: $background-light
  +maxResBounds
  +borderer
  +res-md-min
    display: flex
    align-items: center
    > *
      width: 50%
  @media print
    page-break-inside: avoid
    border: 1px solid $stroke-color
  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi Button"
  &--neutrallight
    background: $fill-neutral-30
  &--secondarydark
    background: $fill-secondary-90
  &--black
    background: $fill-black
  &--black, &--secondarydark
    color: $text-white
    .caption
      color: $text-white
    .description, .caption
      +customContentDarkVariant

  &--imagePos-left
    .main
      +res-lg-min
        padding-right: $wcont-padding
        margin-right: auto
        padding-left: 7.2rem
  &--imagePos-right
    .main
      +res-lg-min
        padding-right: 7.2rem
        padding-left: $wcont-padding
        margin-left: auto
    .imageBox
      +res-md-min
        order: 999

  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi Button"

.main
  padding: 2rem $wcont-padding
  +vSpace(2rem)
  +res-md-min
    max-width: #{$wcont-width-md * .5}

.content
  +vSpace(1.2rem)

.data
  display: block
  +typoTextSm

.title
  +typoH2
  +res-md-max
    +typoH4Mobile

.description
  +typoTextLg
  +customContent
  +res-md-max 
    +typoTextMd

.caption
  color: $text-grey-dark
  +typoTextSm
  +customContent

.ctaBox, .root :global(.block-card-group)
  display: flex
  flex-wrap: wrap
  gap: 1.2rem


.imageBox
  +res-md-min
    min-height: 50rem
    align-self: stretch
  +res-sm-max
    height: 30rem
  img
    width: 100%
    height: 100%
    
\:global(.wcontainer), :global(.block-w-container)
  .root
    +res-sm-max
      margin-left: #{$wcont-padding * -1}
      margin-right: #{$wcont-padding * -1}
    &--imagePos-left
      .main
        +res-lg-min
          padding-right: 7.2rem
    &--imagePos-right
      .main
        +res-lg-min
          padding-left: 7.2rem