.empty-message
  padding: 40px 16px
  text-align: center
  +typoTextMd
  color: $pb-grey-80
  > *
      &:not(:last-child)
          margin-bottom: 10px
  svg
      +style-icon(24px, currentColor)
      margin: 0 auto
  &--small
    padding: 8px 16px
    +typoTextSm
                    
.error-message
  padding: 8px 16px
  border-radius: 6px
  text-align: center
  +typoTextSm
  color: $pb-error-dark
  background: $pb-error-light
  > *
      &:not(:last-child)
          margin-bottom: 10px
  svg
      +style-icon(24px, currentColor)
      margin: 0 auto
                    

.form-buttons
    display: flex
    justify-content: flex-end
    > *
        &:not(:last-child)
            margin-right: 8px

.component-description
  padding: 20px 16px 20px
  color: $pb-grey-90
  +typoTextSm
  &:not(:last-child)
    border-bottom: 1px solid $pb-grey-20
  &__title
    font-weight: 600
    text-transform: uppercase
    margin-bottom: 4px
                    