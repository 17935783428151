@import styles/sass/abstracts

.root
  padding: 2.4rem
  background: $background-light
  color: $text-black
  +vSpace(2rem, 1.6rem)
  border: 1px solid $stroke-color
  +res-sm-max
    padding: 2rem

.icon
  display: block
  svg
    +styleIcon(6rem, $fill-black)
    +res-sm-max
      +sizeIcon(4.8rem)

.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd
