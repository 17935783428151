@import styles/sass/abstracts

.root
  border: 1px solid $stroke-color
  padding: 2.4rem 2.4rem 3.4rem 2.4rem
  +vSpace(2.4rem)

.header
  +res-md-min
    display: flex
    justify-content: space-between
    align-items: center
  +res-sm-max
    +vSpace(2.4rem)
    
.titleHeader
  display: flex
  align-items: center
  gap: 2rem
  +res-sm-max
    gap: 1.2rem
  svg
    +styleIcon(4rem, $fill-black)
    +res-sm-max
      +sizeIcon(2.4rem)

.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.links
  +vSpace(2.4rem)
  > *
    display: block
    text-decoration: underline
