@import styles/sass/abstracts

.modal
  max-width: 48.8rem
  width: calc(100% - 4rem)
  @media print
    display: none

.modalCtaBox
  +res-sm-min
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 1.6rem
    > *
      width: calc(50% - .8rem)
  +res-xs-max
    +vSpace(1.6rem)
    > *
      width: 100%

.buttonWrap
  > *
    width: 100%