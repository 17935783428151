@import styles/sass/abstracts

.root
  position: fixed
  top: 0
  left: 0
  display: flex
  align-items: center
  padding: 0 2rem
  width: 100%
  height: $toolbar-height
  background: #fff
  z-index: 7000
  box-shadow: 0 .2rem .4rem rgba(#000, .12)
.adminLink
  margin-right: 20px
.button
  padding-top: 4px!important
  padding-bottom: 4px!important
.user
  +typoTextSm
  font-weight: 700
  a
    +typoLink
  &--impersonate
    font-weight: 400
    span
      font-weight: 700
.right
  display: flex
  align-items: center
  margin-left: auto
  +res-sm-max
    display: none
  > *
    &:not(:last-child)
      margin-right: 20px

.rightMobile
  display: none
  +res-sm-max
    display: flex
    align-items: center
    margin-left: auto

.actions
  display: flex
  > *
    &:not(:last-child)
      margin-right: 20px
  @media print
    display: none

.button
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  position: relative
  transition: background-color .2s linear, color .2s linear, opacity .2s linear
  font-weight: 600
  font-size: 14px
  line-height: 20px

  &:not(:disabled)
    cursor: pointer

.buttonLabel
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none

.buttonIcon, .buttonLoader
  svg
    +styleIcon(20px, currentColor)

.buttonIcon, .buttonLoader, .buttonLabel
  pointer-events: none

.buttonLoader
  position: absolute
  left: 0
  right: 0
  margin: 0 auto
  top: calc(50% - 10px)
  width: 20px
  svg
    animation: rotation 1s infinite linear

.button
  // Loading
  &--loading
    .buttonLabel, .buttonIcon
      opacity: 0

  &:not(:disabled)
    cursor: pointer

  &:disabled
    opacity: .6
    cursor: default

  // Variant
  &--primary, &--secondary
    border-width: 1px
    border-style: solid
    border-radius: 4px
    padding: 2px 10px
    &:focus-visible
      &:not(:disabled)
        outline-width: 2px
        outline-style: solid
        outline-offset: 2px

  &--primary
    color: $admin-white
    background: $admin-main-default
    border-color: $admin-button-border
    &:not(:disabled):hover
      background: $admin-main-dark

  &--secondary
    border-color: $admin-button-border
    color: $admin-main-default
    &:not(:disabled):hover
      background: $admin-main-default
      color: $admin-white

  &--ghost
    color: $admin-text-default
    &:not(:disabled):hover
      color: $admin-main-dark
    .buttonLabel
      border-bottom: 1px solid currentColor
    .buttonIcon
      opacity: 0
      transition: opacity .2s
    &:hover:not(:disabled)
      .buttonIcon
        opacity: 1
    &:focus-visible
      &:not(:disabled)
        outline-width: 2px
        outline-style: solid
        outline-offset: 0
        .buttonIcon
          opacity: 1

  // Label e/o icon
  &--icon-right
    .buttonIcon
      margin-left: 8px
      order: 1
  &--icon-left
    .buttonIcon
      margin-right: 8px
  &--no-label
    .buttonIcon
      margin: 0

@media print
  .toolbar
    display: none
