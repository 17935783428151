@import styles/sass/abstracts

.root
  left: auto
  right: 0
  top: 0
  height: 100%
  max-height: none
  width: 45rem
  max-width: 100%
  @media screen and (max-height: 699.95px)
    overflow-y: auto
    +scrollbarStyle

.layout
  display: flex
  flex-direction: column
  color: $text-black
  height: 100%

.head
  padding: 2rem $wcont-padding 0

.headContent
  display: flex
  align-items: flex-start
  justify-content: space-between
  border-bottom: 1px solid $stroke-color
  padding-bottom: 2rem

.title
  +typoH5

.close
  svg
    +styleIcon(2.4rem, $fill-black)

.body
  flex: 1
  padding: 0 $wcont-padding
  @media screen and (min-height: 700px)
    overflow-y: auto
    +scrollbarStyle

.list
  > *
    padding-top: 1.2rem
    padding-bottom: 2rem
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color

.footer
  padding: 2rem $wcont-padding
  margin-top: auto
  border-top: 1px solid $stroke-color
  +vSpace(1.4rem)

.promo
  border-bottom: 1px solid $stroke-color
  padding-bottom: 2rem

.ctaBox
  display: flex
  flex-wrap: wrap
  gap: .8rem 2rem 
  > *
    flex: 1
    white-space: nowrap

.empty
  text-align: center
  color: $text-grey-dark
  +typoTextMd
  padding: 2rem
  border: 1px solid $stroke-color
  margin-top: 2rem