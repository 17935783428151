@import styles/sass/abstracts

.root
  color: $text-black
  > *
    &:not(:last-child)
      margin-bottom: 2rem
      border-bottom: 1px solid $stroke-color
      padding-bottom: 2rem

.list
  +typoTextSm
  color: $text-grey-dark
  +vSpace(1rem)

.item, .total
  display: flex
  align-items: center
  justify-content: space-between
  gap: .8rem

.itemValue
  strong
    font-weight: $fw-bold

.total
  +typoTextLg
  font-weight: $fw-semibold

.info
  svg
    +styleIcon(2rem, currentColor)
