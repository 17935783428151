@import styles/sass/abstracts

.root
  padding: 4rem
  background: $fill-secondary-90
  color: $text-white
  +vSpace(2rem)
  +res-sm-max
    padding: 2rem
  +res-md-min
    text-align: center

.content
  +vSpace(1.2rem)

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextMd
  +customContent
  +customContentDarkVariant
  +res-sm-max
    +typoTextMd
