@import styles/sass/abstracts

.root
  position: relative
  &--play
    .imageBox
      opacity: 0
      pointer-events: none

.imageBox
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none
  opacity: 1
  transition: opacity .2s
  z-index: 1

.play
  position: absolute
  top: calc(50% - 3.2rem)
  left: calc(50% - 3.2rem)
  pointer-events: none
  svg
    +styleIcon(6.4rem, $fill-white)

.video
  cursor: pointer