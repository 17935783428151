@import styles/sass/abstracts

.root
  padding: 3.4rem $wcont-padding 2rem
  background: $background-light
  color: $text-black
  bottom: 0
  top: auto
  max-width: none
  width: 100%
  max-height: 80vh
  height: auto
  overflow-y: auto
  +scrollbarStyle

.closeBar
  position: absolute
  top: 0
  left: 0

.head
  display: flex
  align-items: flex-start
  justify-content: space-between
  gap: 2rem
  margin-bottom: 2rem

.title
  +typoH6

.close
  svg
    +styleIcon(2.4rem, $fill-black)


.main, .products
  > *
    &:not(:last-child)
      margin-bottom: 2rem
      border-bottom: 1px solid $stroke-color
      padding-bottom: 2rem

.cta
  width: 100%