@import 'styles/sass/abstracts'
  
.root
  padding-top: $header-height
  transition: padding-top .2s
  +res-menu-desktop-min
    padding-top: $header-height
  +res-menu-mobile-max
    padding-top: $header-height-mobile

  @media print
    padding-top: 0!important

  &--noheader
    +res-menu-desktop-min
      padding-top: 0
    +res-menu-mobile-max
      padding-top: 0
       
.skipToMain
  position: fixed
  top: 1rem
  left: 2rem
  transform: translateY(calc(-100% - 2rem))
  transition: transform .3s ease-in
  z-index: 7001
  &:focus-visible
    transform: translateY(0)

\:global(.toolbar) ~ .root
  +res-menu-desktop-min
    padding-top: #{$header-height + $toolbar-height}
  +res-menu-mobile-max
    padding-top: #{$header-height-mobile + $toolbar-height}
  &--noheader
    +res-menu-desktop-min
      padding-top: $toolbar-height
    +res-menu-mobile-max
      padding-top: $toolbar-height



\:global(.preheader) ~ .root
  +res-menu-desktop-min
    padding-top: #{$header-height + $preheader-height}
  +res-menu-mobile-max
    padding-top: #{$header-height-mobile + $preheader-height}
    +res-sm-max
      padding-top: #{$header-height-mobile + $preheader-height-mobile}
  &--noheader
    +res-menu-desktop-min
      padding-top: 0
    +res-menu-mobile-max
      padding-top: 0


\:global(.toolbar ~ .preheader) ~ .root
  +res-menu-desktop-min
    padding-top: #{$header-height + $preheader-height + $toolbar-height}
  +res-menu-mobile-max
    padding-top: #{$header-height-mobile + $preheader-height + $toolbar-height}
    +res-sm-max
      padding-top: #{$header-height-mobile + $preheader-height-mobile + $toolbar-height}
  &--noheader
    +res-menu-desktop-min
      padding-top: $toolbar-height
    +res-menu-mobile-max
      padding-top: $toolbar-height
