@import styles/sass/abstracts

.formBody
  +vSpace(2.4rem)

.fieldsets
  > *
    &:not(:last-child)
      padding-bottom: 4rem
      border-bottom: 1px solid $stroke-color
      margin-bottom: 4rem

.checkboxes
  width: 100%
  +res-sm-min
    display: flex
    flex-wrap: wrap
    gap: 1.6rem 2rem
  +res-xs-max
    +vSpace(1.6rem)

.circuitoField
  > label
    +typoTextSm
    margin-bottom: 2.4rem

.informative
  > *
    &:not(:last-child)
      padding-bottom: 2.4rem
      border-bottom: 1px solid $stroke-color
      margin-bottom: 2.4rem

.informativa
  +typoTextSm
  +vSpace(1.6rem)

.informativaTitle
  font-weight: $fw-bold

.informativaContent
  +customContent
  color: $text-grey-dark

.informativaScroll
  background: $fill-white
  border: 1px solid $stroke-color
  &:not(:first-child)
    margin-top: 1.6rem
  &:not(:last-child)
    margin-bottom: 1.6rem

.informativaScrollContent
  padding: 1.2rem
  max-height: 15.1rem
  +scrollbarStyle
  overflow-y: auto

.informativaBottom
  +res-sm-min
    display: flex
    justify-content: space-between
    gap: 1.6rem
  +res-xs-max
    +vSpace(1.6rem)

.informativaNotes
  color: $text-grey-dark
  +typoTextSm
  +customContent

.ctaBox
  text-align: center

.notes
  text-align: center
  color: $text-grey-dark
  +typoTextSm
