@import styles/sass/abstracts


.root
  display: flex
  +res-md-min
    gap: 7.4rem
  +res-sm-max
    justify-content: space-between

.dot
  display: block
  padding: 1.3rem
  border-radius: 50%
  border-width: 1px 
  border-style: solid
  +res-sm-max
    padding: .9rem
  svg
    +styleIcon(2rem, currentColor)
    

.label
  display: block
  +typoTextSm
  text-align: center

.item
  position: relative
  display: flex
  align-items: center
  gap: 1.6rem
  +res-sm-max
    gap: 1.2rem

  &:not(:last-child)::after
    content: ""
    position: absolute
    top: 50%
    height: 1px
    background: $stroke-color
    left: calc(100% + 1.6rem)
    width: 4.2rem 
    +res-sm-max
      width: 2.3rem 
      left: calc(100% + 3.6rem)
    +res-xs-max
      width: 1.3rem 
      left: calc(100% + 2rem)
    @media screen and (max-width: 400px)
      content: none

  &:not(.item--active)
    +res-sm-max
      .label
        display: none
      .dot
        svg
          display: none

  &--past
    .dot
      border-color: $fill-grey-dark
      background: $fill-grey-dark
      color: $text-white
    .label
      color: $text-grey-dark
      font-weight: $fw-semibold

  &--active
    .dot
      background: $fill-primary-70
      border-color: $fill-primary-70
      color: $text-white
    .label
      color: $text-primary-70
      font-weight: $fw-semibold

  &--future
    .dot
      border-color: $stroke-color
      color: $fill-neutral-30
      +res-sm-max
        background: $fill-neutral-30
        border-color: $fill-neutral-30
    .label
      color: $text-grey-dark