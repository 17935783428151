.root
  display: block
  @media not all and (min-resolution: 0.001dpcm)
    img[loading="lazy"]
      clip-path: inset(0.5px)

  &--objectFit-cover, &--objectFit-contain
    width: 100%
    height: 100%
    
  &--objectFit-cover
    object-fit: cover

  &--objectFit-contain
    object-fit: contain
