@import styles/sass/abstracts


.root
  text-align: center
  +vSpace(2.4rem)
  +res-sm-max
    padding: $wcont-padding

  &--light
    +res-md-min
      padding: 4rem 3.2rem
      border: 1px solid $stroke-color
    +res-sm-max
      border-top: 1px solid $stroke-color
      padding: 4rem $wcont-padding 2rem
      margin-left: #{$wcont-padding * -1}
      margin-right: #{$wcont-padding * -1}

  &--dark
    background: $fill-neutral-20
    +res-md-min
      padding: 6.6rem 3.2rem
    +res-sm-max
      margin: 0

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.description
  color: $text-grey-dark
  +typoTextLg
  +customContent