@import styles/sass/abstracts

.root
  padding: 8rem 0
  color: $text-black
  +res-sm-max
    padding: 4rem 0

.layout
  +vSpace(4rem, 2rem)
  > :global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi TabellaDifferenzeCard"

.main
  +vSpace(1.2rem, 2rem)
  text-align: center

.title
  +typoH2
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd

.list, .layout > :global(.block-card-group)
  +res-md-min
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 2rem
    > *
      width: calc(50% - 1rem)
  +res-sm-max
    +vSpace(2rem)