@import styles/sass/abstracts

.root
  bottom: 0
  left: 0
  width: 100%
  max-width: none
  top: auto

.closeBar
  position: absolute
  top: 0
  left: 0

.close
  position: absolute
  top: 3.4rem
  right: 2rem
  svg
    +styleIcon(2.4rem, $fill-black)

.layout
  padding: 3.4rem $wcont-padding 2rem
  +vSpace(2rem)
  color: $text-black
  
.tags
  display: flex
  align-items: flex-start
  flex-wrap: wrap
  gap: .8rem
  padding-right: 3.2rem

.content
  +vSpace(1.2rem)

.title
  +typoH5

.indirizzo
  +typoTextSm

.details
  +vSpace(1.2rem)
  +typoTextSm
  font-weight: $fw-semibold

.detailItem
  display: flex
  align-items: center
  gap: .8rem

.detailValue
  text-decoration: underline

.detailIcon
  svg
    +styleIcon(2.4rem, $fill-black)

.list
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  gap: .8rem

.cta
  width: 100%