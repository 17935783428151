.left-sidebar
    .navigator
        .tree-node-container
            position: relative
            padding-left: 12px
            &::before
                content: ""
                position: absolute
                left: 23px
                top: 26px
                bottom: 0
                border-left: 1px solid $pb-grey-50
        > .tree-node-container
            padding-left: 0
            &::before
                left: 11px

        .tree-node
            display: flex
            cursor: pointer
            padding: 4px 8px 4px 4px
            +typoTextMd
            &:hover
                background-color: $pb-grey-20
            &--active
                background-color: $pb-grey-20
            &--leaf
                padding-left: 24px

            &__selection-scope
                position: relative
                z-index: 2
                flex: 1
                display: flex
                span
                    flex: 1
                svg
                    margin-top: 1px
                    +style-icon(16px, currentColor)
                    margin-right: 5px

            &__dropdown-trigger
                margin-right: 4px
                svg
                    +style-icon(16px, currentColor)

            &__actions
                margin-left: auto
                display: flex
                svg
                    +style-icon(16px, currentColor)

        .tree-node-indent
            display: flex