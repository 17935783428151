@import styles/sass/abstracts

.root
  padding: 2rem
  +vSpace(1.6rem)
  color: $text-black
  transition: background .3s
  cursor: pointer
  &--selected
    background: $fill-neutral-30
  
.tags
  display: flex
  align-items: flex-start
  flex-wrap: wrap
  gap: .8rem

.main
  +vSpace(.8rem)

.content
  +vSpace(.4rem)

.title
  +typoTextLg
  font-weight: $fw-semibold

.indirizzo
  +typoTextSm

.list
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  gap: .8rem