@import styles/sass/abstracts

.root
  color: $text-black
  background: $fill-neutral-30
  display: flex
  flex-direction: column
  &--secondarydark
    color: $text-white
    background: $fill-secondary-90
    .description
      +customContentDarkVariant

  &--horizontal
    +res-md-min
      flex-direction: row
      .imageBox
        min-width: 24rem
        height: auto
        margin-top: 0

.main
  +vSpace(2rem)
  padding: 2.4rem

.content
  +vSpace(1.2rem)

.title
  +typoH4

.description
  +typoTextSm
  +customContent

.imageBox
  height: 28rem
  margin-top: auto
