.pb-overlay-modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(#000, .3)
  z-index: 8000
.pb-modal 
  position: fixed
  top: 50%
  transform: translateY(-50%)
  left: 20px
  right: 20px
  max-width: 630px
  margin: 0 auto
  padding: 40px 30px 30px
  background: #fff
  z-index: 8001
  &__close
    position: absolute
    top: 32px
    right: 32px
    cursor: pointer
    svg
      width: 16px
      height: 16px
      display: block
      +color-icon($pb-grey-100)
  &__body
    max-height: calc(100vh - 220px)
    overflow-y: auto
    +scrollbar-style
    padding-right: 30px
    margin-right: -30px
    &__title
      +typoTextLg
      color: $pb-grey-100
      text-align: center
      margin-bottom: 30px
    &__text
      +typoTextMd
      color: $pb-grey-100
      text-align: center


  