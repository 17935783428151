@import styles/sass/abstracts

.root
  border: 1px solid $stroke-color
  display: flex
  align-items: center
  gap: .8rem
  padding: 1rem
  min-height: 7.7rem
  color: $text-black
  &:not(.root--empty)
    +res-md-min
      background: $fill-neutral-20

.imageBox
  width: 4rem
  height: 4rem

.title
  flex: 1
  +typoTextSm

.close
  +styleIcon(2.4rem, $fill-black)