@import styles/sass/abstracts

.root
  &--openResults
    .mapListBox
      +res-md-min
        display: block
    .map
      +res-md-min
        padding-left: 40rem

.layout
  +vSpace(4rem, 2rem)

.head
  +vSpace(2.4rem)

.content
  +vSpace(1.6rem)

.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.description
  +typoTextMd
  +customContent

.form
  display: flex
  align-items: flex-start
  gap: 1.6rem
  +res-xs-max
    flex-wrap: wrap

.searchBar
  flex: 1
  position: relative
  +res-sm-min
    max-width: 40rem

.searchButton
  +res-xs-max
    width: 100%

.mapLayout
  position: relative
  +res-sm-max
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}

.map
  height: 60rem
  background: #ccc
  +res-sm-max
    height: 45rem

.mapNav
  display: flex
  +res-md-min
    display: none
  > *
    width: 50%
    +typoTextMd
    padding: 1rem
    border: 1px solid $stroke-color
    &:focus-visible
      outline-offset: -2px
    &:disabled
      font-weight: $fw-semibold
      background: $fill-black
      border-color: $fill-black
      color: $text-white
    &:not(:disabled)
      text-decoration: underline
  &--hidden
    display: none

.mapListBox
  overflow-y: auto
  +scrollbarStyle
  +res-md-min
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 40rem
    border: 1px solid $stroke-color
    background: $background-light
    z-index: 2
    display: none

.mapEmptyList
  padding: 4rem 2rem
  text-align: center
  +typoTextLg
  +res-sm-max
    border-bottom: 1px solid $stroke-color

.mapList
  +res-sm-max
    border-top: 0
    border-bottom: 0
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color
    +res-sm-max
      &:last-child
        border-bottom: 1px solid $stroke-color // Nella versione mobile ho tolto il border dell'intera lista perchè ho gli ultimi elementi nascosti

.mapListMoreBox
  padding: 2rem $wcont-padding
  +res-md-min
    display: none

.mapListMore
  width: 100%

.mapNavItem, .mapItem
  &--hidden
    +res-sm-max
      display: none

.selectedRivenditoreLayer
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  background: $background-light
  color: $text-black
  padding: 3.4rem $wcont-padding 2rem
  z-index: 2
  +vSpace(2rem)
  cursor: pointer
  +res-md-min
    display: none

.selectedRivenditoreLayerCloseBar
  position: absolute
  top: 0
  left: 0

.selectedRivenditoreLayerTitle
  +typoH5

.option
  padding: 1.2rem
  display: block
  width: 100%
  text-align: left
  +typoTextSm
  &:hover
    background: $fill-neutral-20
  &:focus-visible
    outline-offset: -2px
  &--selected
    font-weight: $fw-semibold

.suggestionsList
  position: absolute
  max-height: 24rem
  overflow-y: auto
  z-index: 3
  margin-top: .8rem
  border-radius: .8rem
  border: 1px solid $stroke-color
  background: $fill-white
  +scrollbarStyle
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color
