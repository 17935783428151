@import styles/sass/abstracts

.root
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  position: fixed
  text-align: center
  padding: 4rem
  color: $text-black
  +scrollbarStyle

.content
  +vSpace(2.4rem)

.close
  position: absolute
  top: 2rem
  right: 2rem
  svg
    +styleIcon(2.4rem, $fill-black)

.icon
  display: block
  svg
    +styleIcon(4rem, currentColor)
    margin-left: auto
    margin-right: auto

.title 
  +typoH4
  +res-sm-max
    +typoH5Mobile

.subtitle
  +typoTextMd
  +customContent
  +res-sm-max
    +typoTextSm
  a
    font-weight: $fw-regular
    color: $text-primary-70

.description
  color: $text-grey-dark
  +typoTextSm
  +customContent
  +res-sm-max
    +typoTextXs
  a
    font-weight: $fw-regular
    color: $text-primary-70

.ctaBox
  display: flex
  gap: 1.6rem
  flex-wrap: wrap
  justify-content: center