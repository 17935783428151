@import styles/sass/abstracts

.root
  position: relative
  color: $text-black
  &--open
    &::after
      opacity: 1
    .content
      max-height: none
    .badge
      display: none
    &:not(.root--transitioning)
      .content
        overflow: visible

  &:not(.root--open):not(.root--transitioning)
    .innerContent
      @media screen
        visibility: hidden

  &--filter
    .trigger
      +typoBase(1.2rem, 2.4rem, $fw-semibold)
      color: $text-grey-dark
      gap: .9rem
    .innerContent
      padding-top: .8rem

  &--faq
    .trigger
      font-weight: $fw-semibold
      text-transform: none

  &--editMode
    .content
      padding-top: 2rem
    .innerContent
      border: 1px dashed $stroke-color
      padding: 2rem
      

.trigger
  +typoTextMd
  text-transform: uppercase
  display: flex
  align-items: center
  gap: 1.6rem
  width: 100%
  text-align: left
  &:focus-visible
    outline: none
    &::after
      content: ""
      pointer-events: none
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      +focusVisibleStd
      z-index: 1

.badge
  +typoTextXs
  font-weight: $fw-semibold
  color: $text-white
  width: 2rem
  height: 2rem
  background: $fill-primary-70
  border-radius: 50%
  text-align: center
  padding: 2px 0
  display: block

.arrow
  margin-left: auto
  svg
    +styleIcon(2.4rem, $fill-black)
  @media print
    display: none

.content
  max-height: 0
  overflow: hidden
  transition: max-height .3s linear
  // nel mobile possono esserci elementi che sforano, come gli slider
  +res-sm-max
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
    padding-left: $wcont-padding
    padding-right: $wcont-padding
  @media print
    max-height: none

.innerContent
  padding-top: 2.4rem

.category
  display: block
  +typoTextXs
  text-transform: uppercase
  color: $text-grey-dark

.productName
  display: block
  text-transform: none
  +typoTextLg
  font-weight: $fw-semibold

:global(.block-accordion)
  .content
    +customContent