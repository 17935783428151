@import 'styles/sass/abstracts'

=buttonSm
  +typoButtonSm
  &.root--primary
    padding: .6rem 1.6rem
  &.root--secondary
    padding: .5rem 1.5rem
  &.root--ghost
    padding: .6rem 0 .5rem
  .icon, .loader
    +sizeIcon(2rem)

=buttonMd
  +typoButtonMd
  &.root--primary
    padding: .8rem 2rem
  &.root--secondary
    padding: .7rem 1.9rem
  &.root--ghost
    padding: .8rem 0 .7rem
  .icon, .loader
    +sizeIcon(2rem)

=buttonLg
  +typoButtonMd
  &.root--primary
    padding: 1.2rem 2.4rem
  &.root--secondary 
    padding: 1.1rem 2.3rem
  &.root--ghost
    padding: 1.2rem 0 1.1rem
  .icon, .loader
    +sizeIcon(2.4rem)


=ghostNoLabel 
  padding: 0
  border-bottom: none


.loader
  position: absolute
  left: 0
  right: 0
  margin: 0 auto
  top: 50%
  transform: translateY(-50%)
  svg
    animation: rotation 1s infinite linear


.label
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    +res-md-min
      display: none

.root
  position: relative
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  gap: 1rem
  transition: background-color .2s linear, color .2s linear, border-color .2s linear
  &:focus-visible
    +focusVisibleStd

  &--dark
    &:focus-visible
      outline-color: $text-white

  // Loading
  &--loading
    .label, .icon
      opacity: 0

  &--primary, &--secondary
    border-radius: 2.4rem

  &--secondary, &--ghost
    color: $fill-primary-70
    .label
      color: $text-black
    // &:hover:not(:disabled), &:active:not(:disabled) - uguale a non-hover
    // &:focus-visible
    //   outline-color: $fill-primary-70
    &:disabled
      color: $fill-disabled
      .label
        color: $text-disabled

  &--primary
    background-color: $fill-primary-70
    color: $text-white
    &:hover:not(:disabled), &:active:not(:disabled)
      background-color: $fill-primary-80
    &:disabled
      background-color: $fill-neutral-30
      color: $text-grey-dark
    @media print
      color: $text-black!important
      outline: 1px solid $fill-primary-70!important
      outline-offset: -1px!important

  &--secondary
    border: 1px solid currentColor

  &--ghost
    border-bottom: 1px solid currentColor

  // Color
  &--white
    &.root--primary
      background-color: $fill-white
      color: $text-black
      &:hover:not(:disabled), &:active:not(:disabled)
        background-color: $fill-neutral-20
      &:disabled
        background: $fill-grey-dark
    &.root--secondary, &.root--ghost
      color: $fill-white
      .label
        color: currentColor

  // Size
  &--sm
    +buttonSm

  &--md
    +buttonMd

  &--lg
    +buttonLg

  // Mobile Size
  &--mobile-sm
    +res-sm-max
      +buttonSm

  &--mobile-md
    +res-sm-max
      +buttonMd

  &--mobile-lg
    +res-sm-max
      +buttonLg

  // icons
  &--hideDesktopIcon
    +res-md-min
      .icon
        display: none
  &--hideMobileIcon
    +res-sm-max
      .icon
        display: none

  // Label
  &--no-label
    &.root--ghost
      +ghostNoLabel

  &--no-label-desktop
    +res-md-min
      &.root--ghost
        +ghostNoLabel
      .label
        &:not(:label--mobile)
            display: none

  &--no-label-mobile
    +res-sm-max
      &.root--ghost
        +ghostNoLabel
      .label
        &:not(.label--desktop)
          display: none