@import "../../styles/sass/abstracts"

.ROOT
  &--editing
    .block-card-group
      position: relative
      min-height: 4rem
      &--empty
        &::before
          position: absolute
          top: 50%
          transform: translateY(-50%)
          left: 0
          width: 100%
          content: "Trascina qui gli elementi Card"
          +typoPagebuilderHint