@import styles/sass/abstracts

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.layout
  +vSpace(4rem, 2.4rem)

.dati
  +vSpace(1.6rem)
  
.datiInfo
  +res-sm-min
    display: flex
    flex-wrap: wrap
    gap: 1.6rem
    > *
      width: calc( 50% - .8rem )
  +res-xs-max
    +vSpace(1.6rem)

.datiInfoItem
  +vSpace(.4rem)
  +res-xs-max
    display: flex
    flex-wrap: wrap
    gap: 0 .6rem

.datiInfoItemLabel
  +typoTextSm
  font-weight: 600

.datiInfoItemValue
  +res-sm-max
    +typoTextSm

.inlineCardContent
  +res-md-min
    display: flex
    align-items: center
    gap: 1.6rem
  +res-sm-max
    +vSpace(1.6rem)

.inlineCardCta
  +res-md-min
    margin-left: auto

.modal
  +res-sm-max
    width: calc( 100% - $wcont-padding)