@import styles/sass/abstracts

.root
  background: $fill-neutral-20
  color: $text-black
  +res-sm-max
    display: flex
  &--modal
    display: flex
    align-items: center
    gap: 1.6rem
    padding: 1rem
    +res-sm-max
      align-items: flex-start
    .imageBox
      width: 6.4rem
      height: 6.4rem
    .main
      flex: 1
      padding: 0
      +res-md-min
        display: flex
        align-items: center
        gap: 1.6rem
        +vSpace(0)
      +res-sm-max
        +vSpace(.8rem)
    .content
      flex: 1
      +vSpace(.8rem)
    .title
      +typoTextMd
    .description
      +typoTextXs
    .price
      white-space: nowrap
      +typoTextSm

.imageBox
  background: $fill-white
  +res-sm-max
    width: 9rem
  +res-md-min
    height: 15rem

.main
  padding: 1.2rem
  +vSpace(.6rem)
  +res-sm-max
    flex: 1

.content
  +vSpace(.6rem)

.title, .price
  +typoTextLg
  font-weight: $fw-semibold

.description
  +typoTextSm
  +customContent
  color: $text-grey-dark
