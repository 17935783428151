// Pagebuilder
.pb-content
  &.active
    height: 80vh
    margin-bottom: 3rem
    position: relative
    > .pagebuilder-placeholder
      border: none
      width: 100%
      height: 100%
  .component-hidden
    display: none  // TODO: vedere con l'Ali la soluzione ottimale
