@import styles/sass/abstracts

.root
  padding: 8rem 0
  background: $fill-neutral-20
  color: $text-black
  +res-sm-max
    padding: 4rem 0

.layout
  +vSpace(4rem, 1.6rem)

.main
  +vSpace(2rem)
  text-align: center

.title
  +typoH2
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextLg
  +customContent
  +res-sm-max 
    +typoTextMd

.tab
  +vSpace(4rem, 2rem)
  &:not(.tab--visible)
    display: none

.tabList
  +res-md-min
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap: 4rem 0
    > *
      +res-xl-min
        width: 25%
        &:not(:nth-child(4n)):not(:last-child)
          border-right: none
      +res-lg-max
        width: 50%
        &:not(:nth-child(2n)):not(:last-child)
          border-right: none
  > *
    max-width: 40rem

  +res-sm-max
    +vSpace(2rem)
    > *
      margin-left: auto
      margin-right: auto
  
.tabCtaBox
  text-align: center