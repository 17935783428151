@import styles/sass/abstracts

.root
  border: 1px solid $stroke-color
  padding: 2.3rem
  background: $fill-neutral-20
  +vSpace(2.4rem)

.searchLayout
  display: flex
  align-items: flex-end
  gap: 2rem

.searchField
  flex: 1

.searchLabel
  display: block
  color: $text-black
  margin-bottom: .4rem
  +typoTextXs