@import styles/sass/abstracts

.root
  position: relative
  padding: 0 3.9rem
  border: 1px solid $stroke-color
  background: $fill-white
  border-radius: .8rem

.control
  position: absolute
  top: 0
  height: 100%
  padding: .8rem
  svg
    +styleIcon(2.4rem, $fill-black)
  &--plus
    right: 0
  &--minus
    left: 0

.input
  padding: .7rem 0
  text-align: center
  border: none
  width: 100%
  background: none
  color: $text-black
  +typoTextMd
  -moz-appearance:textfield
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button 
    -webkit-appearance: none
  




