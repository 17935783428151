@import styles/sass/abstracts

.root
  color: $text-black
  display: flex
  width: 100%
  align-items: center
  gap: 2rem
  padding: 1.2rem
  +typoTextMd
  &--active
    color: $text-primary-70
    font-weight: $fw-semibold

.icon
  svg
    +styleIcon(2.4rem, currentColor)

.label
  flex: 1