@import styles/sass/abstracts

.root
  background-color: $fill-neutral-20
  padding: 2.4rem
  display: flex
  flex-wrap: wrap
  gap: 0.8rem
  justify-content: space-between
  align-items: center
  +res-sm-max
    padding: 1.6rem
    gap: 1.6rem
    flex-direction: column
    align-items: flex-start

.content
  flex: 1

.info
  display: flex
  gap: 0.8rem
  align-items: center
  +res-sm-max
    flex-direction: column-reverse
    align-items: flex-start

.name
  +typoTextSm
  font-weight: 700

.description
  margin-top: 0.8rem
  +typoTextSm

.actions
  display: flex
  gap: 1.6rem
  flex-wrap: wrap
  align-items: center

.btnIcon
  width: 3.2rem
  height: 3.2rem

.defaultBtn
  +res-sm-max
    order: 1