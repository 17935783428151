// Utilities

@mixin placeholderStyle
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::placeholder
    @content

@mixin colorIcon($value, $value2: null)
  color: $value
  @if $value2
    \:global(.c2)
      color: $value2

@mixin sizeIcon($size)
  width: $size
  height: $size

@mixin styleIcon($size, $color, $color2: null)
  display: block
  +sizeIcon($size)
  +colorIcon($color, $color2)

@mixin widen($amount)
  padding-left: $amount
  padding-right: $amount
  margin-left: #{$amount * -1}
  margin-right: #{$amount * -1}

@mixin vSpace($default, $mobile: null)
  > *
    &:not(:last-child)
      margin-bottom: $default
      @if $mobile
        +res-sm-max
          margin-bottom: $mobile


@mixin multilineEllipsis($lines, $line-height)
  max-height: $lines * $line-height // fallback per IE
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: $lines
  -webkit-box-orient: vertical

@mixin scrollbarStyle
  &::-webkit-scrollbar
    background: $fill-neutral-20
    width: .6rem
    border-radius: 1rem

  &::-webkit-scrollbar-thumb
    background: $fill-black
    border-radius: 1rem

@mixin horizontalScrollbarStyle
  &::-webkit-scrollbar
    background: $fill-neutral-20
    height: .6rem

  &::-webkit-scrollbar-thumb
    background: $fill-black
    border-radius: 1rem

@mixin customContent
  \:global(.pcaption)
    +typoTextSm
    color: $text-grey-dark
  .pcaption
    +typoTextSm
    color: $text-grey-dark
  p
    &:not(:last-child)
      margin-bottom: .625em

  \:global(.heading2), :global(.heading3)
    &:not(:last-child)
      margin-bottom: 1.2rem
  .heading2, .heading3
    &:not(:last-child)
      margin-bottom: 1.2rem

  \:global(.heading2)
    +typoH4
  .heading2
    +typoH4
  \:global(.heading3)
    +typoH5
  .heading3
    +typoH5

  \:global(.plarge)
    +typoTextLg
  .plarge
    +typoTextLg

  \:global(.psecondarylarge)
    +typoTextXxxl
    font-family: $ff-secondary
    strong
      font-weight: $fw-bold
    +res-sm-max
      +typoTextXxl

  .psecondarylarge
    +typoTextXxxl
    font-family: $ff-secondary
    strong
      font-weight: $fw-bold
    +res-sm-max
      +typoTextXxl

  a
    +typoLink
  ul, ol
    &:not(:first-child)
      margin-top: 1.2em 
    &:not(:last-child)
      margin-bottom: 1.2em 
  ul
    list-style: none
    padding: 0
    &.checks
      +vSpace(1.2rem)
      li
        padding-left: 3.2rem
        &::before
          width: 2.4rem
          height: 2.4rem
          top: calc(50% - 1.2rem)
          content: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z" fill="%23161616"/></svg>')
    &:global(.checks)
      +vSpace(1.2rem)
      li
        padding-left: 3.2rem
        &::before
          width: 2.4rem
          height: 2.4rem
          top: calc(50% - 1.2rem)
          content: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z" fill="%23161616"/></svg>')
        
    li
      position: relative
      list-style: none
      padding-left: 1.6em
      &::before
        content: "•"
        text-align: center
        position: absolute
        top: 0
        left: 0
        width: 1.6em

  ol
    &.ol-letters
      list-style: lower-alpha
    &.ol-lower-roman
      list-style: lower-roman
    &:global(.ol-letters)
      list-style: lower-alpha
    &:global(.ol-lower-roman)
      list-style: lower-roman
      // list-style: none
      // counter-reset: list
      // padding-left: 0
      // > li
      //   list-style: none
      //   position: relative
      //   padding-left: 1.5em
      //   &::before
      //     content: counter(list, lower-alpha) ". "
      //     counter-increment: list
      //     position: absolute
      //     top: 0
      //     left: 0
      //     width: 1.3em
      //     text-align: right          

  table
    border-collapse: collapse
    +typoTextSm
    color: $text-grey-dark
    border-color: $stroke-color
    td, th
      padding: 1.6rem
    th
      color: $text-black
      font-weight: $fw-semibold
      text-align: left
    &:not(:first-child)
      margin-top: 4rem
      +res-sm-max
        margin-top: 2.4rem
    &:not(:last-child)
      margin-bottom: 4rem
      +res-sm-max
        margin-bottom: 2.4rem
      

@mixin customContentDarkVariant
  a
    +typoLinkDarkVariant
  \:global(.pcaption)
    color: $text-white
  .pcaption
    color: $text-white
  
  // ul
  //   &.checks, &:global(.checks)
  //     li
  //       &::before
  //         content: url('data:image/svg+xml;utf8,<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.2071 6.29289C20.5977 6.68342 20.5977 7.31658 20.2071 7.70711L10.0743 17.84L4.37534 13.2809C3.94408 12.9359 3.87416 12.3066 4.21917 11.8753C4.56418 11.444 5.19347 11.3741 5.62473 11.7191L9.92582 15.16L18.7929 6.29289C19.1835 5.90237 19.8166 5.90237 20.2071 6.29289Z" fill="%23d9fc75"/></svg>')

@mixin maxResBounds
  max-width: $res-max
  margin-left: auto
  margin-right: auto

@mixin focusVisibleStd
  outline: 2px solid $fill-black
  outline-offset: .4rem

@mixin focusVisibleStdDark
  +focusVisibleStd
  // outline-color: $kiwi

@mixin spacer
  &--st-lg
    padding-top: $space-lg
    +res-sm-max
      padding-top: $space-lg-mobile
  &--sb-lg
    padding-bottom: $space-lg
    +res-sm-max
      padding-bottom: $space-lg-mobile
  &--st-md
    padding-top: $space-md
    +res-sm-max
      padding-top: $space-md-mobile
  &--sb-md
    padding-bottom: $space-md
    +res-sm-max
      padding-bottom: $space-md-mobile
  &--st-sm
    padding-top: $space-sm
    +res-sm-max
      padding-top: $space-sm-mobile
  &--sb-sm
    padding-bottom: $space-sm
    +res-sm-max
      padding-bottom: $space-sm-mobile
  &--st-none
    padding-top: 0
    +res-sm-max
      padding-top: 0
  &--sb-none
    padding-bottom: 0
    +res-sm-max
      padding-bottom: 0

@mixin borderer
  &--bt
    border-top: 1px solid $stroke-color
  &--bb
    border-bottom: 1px solid $stroke-color

