@import styles/sass/abstracts

.layout
  +vSpace(2.4rem)

.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.list
  display: flex
  flex-wrap: wrap
  gap: 2.4rem 
  > *
    width: calc(20% - 1.92rem)
    +res-md-only
      width: calc(33.33% - 1.6rem)
    +res-sm-max
      width: calc(50% - 1.2rem)

.item
  +typoTextLg
  text-decoration: underline
