@import styles/sass/abstracts

.layout
  +vSpace(1.6rem)

.main
  +vSpace(1.6rem)
  +res-md-min
    text-align: center

.title
  +typoH5
  +res-sm-max
    +typoH6Mobile

.description
  +typoTextMd
  +customContent
  +res-sm-max
    +typoTextSm

.form
  +vSpace(4rem, 2.4rem)

.formBody
  +vSpace(2.4rem)

.privacyMessage
  +typoTextSm
  color: $text-grey-dark
  +customContent  

.ctaBox
  text-align: center

.notes
  text-align: center
  color: $text-grey-dark
  +typoTextSm