@import 'styles/sass/abstracts'

.root
  position: relative
  &--filtroRadio
    .options
      +vSpace(1.2rem)
  &--horizontal
    .options
      display: flex
      flex-wrap: wrap
      gap: 2rem
      +vSpace(0)

.inputWrap
  position: relative
  display: block
  cursor: pointer
  color: $text-black

.main
  padding-left: 3.2rem
  +res-md-min
    display: flex
    gap: 2rem
  +res-sm-max
    +vSpace(.4rem)

.content
  +vSpace(.4rem)
  padding-top: .15rem
  flex: 1
  .label
    padding-left: 0

.title
  padding: .15rem 0
  font-weight: $fw-semibold

.detailLabel
  font-weight: $fw-semibold

.input
  position: absolute
  left: -9999px

  &:focus-visible
    &+ .styledInput
      outline: 2px solid $fill-black
      outline-offset: -3px
      border-radius: 50%

.styledInput
  position: absolute
  left: 0
  top: 0
  svg
    +styleIcon(2.4rem, $fill-black, $fill-primary-70)

.option
  min-height: 2.4rem
  +typoTextSm
  &--open
    .map
      display: block
  &:not(.option--disabled)
    .inputWrap
      cursor: pointer
    &:hover
      .input:checked
        &+ .styledInput
          svg
            +colorIcon($fill-black, $fill-black)

.options
  +vSpace(2.4rem)

.map
  margin-top: 2.4rem
  display: none
  border: none
  width: 100%
  height: 32.9rem
