@import styles/sass/abstracts

.root
  padding: 1rem
  display: flex
  align-items: flex-start
  gap: 1.6rem
  color: $text-black
  +typoTextXs
  background: $fill-neutral-20

.imageBox
  width: 6.4rem

.main
  flex: 1
  +vSpace(.8rem)

.content
  +vSpace(.4rem)

.categoria
  text-transform: uppercase
  color: $text-grey-dark

.title
  +typoTextSm
  font-weight: $fw-semibold

.details
  display: flex
  justify-content: space-between
  gap: .8rem

.quantityValue
  font-weight: $fw-semibold

.prices
  +vSpace(.4rem)
  text-align: right

.price
  &:not(.price--current)
    color: $text-grey-dark
    .priceValue
      text-decoration: line-through
  &--current
    +typoTextSm
    font-weight: $fw-semibold
