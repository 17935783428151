@import styles/sass/abstracts

.block
  border-bottom: 1px solid $stroke-color
  padding-bottom: 2rem
  margin-bottom: 2rem

.indirizzoBlock2
  padding-top: 2rem
  border-top: 1px solid $stroke-color
