@import styles/sass/abstracts

.root
  position: relative

.input
  width: 100%
  +typoTextMd
  padding: .7rem 1.2rem
  border-radius: 4rem
  border: 1px solid $stroke-color
  background: $fill-neutral-20
  transition: border-color .2s, box-shadow .2s, background-color .2s
  box-shadow: 0 0 0 0 rgba($fill-black, 0)
  +placeholderStyle
    color: $text-black
  &:focus
    border-color: $fill-black
    box-shadow: 0 0 0 1px $fill-black
