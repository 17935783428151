@import styles/sass/abstracts

.root
  padding: .4rem 0
  background: $background-light
  color: $text-black
  +maxResBounds
  &--neutrallight
    background: $fill-neutral-30
  &--secondarydark, &--black
    color: $text-white
  &--secondarydark
    background: $fill-secondary-90
  &--black
    background: $fill-black
  \:global(.swiper)
    +res-lg-min
      padding-left: 7.2rem
      padding-right: 7.2rem
  \:global(.swiper-slide)
    width: auto!important
    
    
  \:global(.swiper-button-prev), :global(.swiper-button-next)
    top: 0
    width: 20rem
    height: 100%
    padding: 0
    border-radius: 0
    &::after
      content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z" fill="%23161616"/></svg>')
      position: absolute
      width: 4rem
      height: 4rem
      top: calc(50% - 2rem)
      padding: .8rem
      background: $fill-neutral-30
      border-radius: 50%

    &:global(.swiper-button-disabled)
      display: none

  \:global(.swiper-button-prev)
    background: linear-gradient(270deg, rgba($background-light-r, $background-light-g, $background-light-b, 0) 0%, $background-light 75%)
    left: 0
    &::after
      left: 2rem
    +res-md-max
      display: none

  \:global(.swiper-button-next)
    background: linear-gradient(90deg, rgba($background-light-r, $background-light-g, $background-light-b, 0) 0%, $background-light 75%)
    right: 0
    &::after
      right: 2rem
    +res-md-max
      display: none


  