@import styles/sass/abstracts

.content
  +vSpace(.8rem)

.bottom
  display: flex
  align-items: center
  justify-content: space-between
  gap: 2rem
