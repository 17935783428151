@import styles/sass/abstracts

.root
  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi Blog image gallery card"
  \:global(.block-card-group)
    display: flex
    flex-wrap: wrap
    gap: 2.4rem
    > *
      flex: 1
      min-width: calc(25% - 1.8rem) 
  
  &--n1, &--nMany
    .item
      +res-md-min
        width: 100%
  &--n2
    .item
      +res-md-min
        width: calc(50% - 1.2rem)
  &--n3
    .item
      +res-md-min
        width: calc(33.33% - 1.6rem)
  &--n4
    .item
      +res-md-min
        width: calc(25% - 1.8rem)
 
.list
  +res-md-min 
    display: flex
    align-items: center
    gap: 2.4rem
  +res-sm-max
    +vSpace(2rem)

.item
  img
    width: 100%
    height: auto

.slider
  \:global(.swiper)
    +widen($wcont-padding)