@import 'styles/sass/abstracts'
  
.root
  display: flex
  flex-wrap: wrap
  column-gap: 0
  margin-left: -.8rem
  margin-right: -.8rem
  > *
    padding-left: .8rem
    padding-right: .8rem

  &--space-lg
    row-gap: 2.4rem
  &--space-md
    row-gap: 2rem
  &--space-sm
    row-gap: 1.6rem
  &--space-none
    row-gap: 0
      