@import styles/sass/abstracts

.root
  position: relative
  background: $fill-neutral-30
  color: $text-black
  display: flex
  flex-direction: column
  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi Button"
  &--secondarydark, &--primary, &--black
    color: $text-white
    .caption
      color: $text-white
    .description, .caption
      +customContentDarkVariant
  &--secondarydark
    background: $fill-secondary-90
  &--primary
    background: $fill-primary-70
  &--black
    background: $fill-black

  &--horizontal
    +res-md-min
      flex-direction: row
      .main
        align-self: center

      .imageBox
        height: auto
        width: 31.5rem
        min-height: 32rem
        border-left: 1px solid $stroke-color
    +res-sm-max
      &.root--top
        .imageBox
          order: -1


  &--vertical
    &.root--top
      .imageBox
        order: -1

.main
  padding: 2.4rem
  +vSpace(2rem)
  +res-md-min
    flex: 1

.content
  +vSpace(1.2rem)

.data
  display: block
  +typoTextSm

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextMd
  +customContent

.caption
  color: $text-grey-dark
  +typoTextSm
  +customContent

.imageBox
  height: 30rem
  img
    width: 100%
    height: 100%
    
.ctaBox, .root :global(.block-card-group)
  display: flex
  flex-wrap: wrap
  gap: 1.2rem