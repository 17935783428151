.pb-button
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  position: relative

  &:not(:disabled)
    cursor: pointer

  &__icon, &__loader
    svg
      display: block
      +color-icon(currentColor)

  &__icon
    margin-right: 5px
  
  &__loader
    position: absolute
    left: 0
    right: 0
    margin: 0 auto
    svg
      animation: rotation 1s infinite linear

  // Loading
  &--loading
    .pb-button
      &__label, &__icon
        opacity: 0

  // Variant
  &--primary
    background-color: $pb-accent-default
    color: $pb-white 

    &:hover:not(:disabled)
      background-color: $pb-accent-dark

    &:disabled
      background-color: $pb-grey-50
    
    &--white
      color: #000
      background-color: #FFF
      &::after
        background: $brand-accent
      +res-md-min
        &:hover:not(:disabled)
          color: #FFF

  &--secondary
    border: 1px solid currentColor
    color: $pb-accent-default

    &:hover:not(:disabled)
      border-color: $pb-accent-dark
      color: $pb-accent-dark

    &:disabled
      border-color: $pb-accent-light
      color: $pb-accent-light
    
    
    &--white
      color: #FFF
      border-color: #FFF

      &::after
        background: $brand-accent
      +res-md-min
        &:hover:not(:disabled)
          border-color: $brand-accent
    
  &--ghost
    color: $pb-accent-default
    padding: 0

    &:hover:not(:disabled)
      color: $pb-accent-dark

    &:disabled
      color: $pb-accent-light
    
    &--white
      color: #FFF

  // Size
  &--sm
    border-radius: 2px
    +typoTextSm
    .pb-button
      &__icon, &__loader
        +size-icon(16px)
      &__loader
        top: calc(50% - 8px)
        width: 16px
    &.pb-button--primary
      padding: 4px 12px
    &.pb-button--secondary
      padding: 3px 11px
    &.pb-button--ghost
      padding: 4px 0

  &--md
    border-radius: 4px
    +typoTextMd
    .pb-button
      &__icon, &__loader
        svg 
          +size-icon(20px)
      &__loader
        top: calc(50% - 10px)
        width: 20px
    &.pb-button--primary
      padding: 6px 14px
    &.pb-button--secondary
      padding: 5px 13px
    &.pb-button--ghost
      padding: 6px 0

  &--lg
    border-radius: 4px
    +typoTextLg
    .pb-button
      &__icon, &__loader
        svg 
          +size-icon(24px)
      &__loader
        top: calc(50% - 12px)
        width: 24px
    &.pb-button--primary
      padding: 8px 16px
    &.pb-button--secondary
      padding: 7px 15px
    &.pb-button--ghost
      padding: 8px 0

  // Icon
  &--icon-right
    .pb-button
      &__icon
        margin-right: 0
        margin-left: 5px
      &__label
        order: -1

  &--icon // no label
    .pb-button
      &__icon
        margin-right: 0
        margin-left: 0