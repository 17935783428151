body
  padding: 0!important
  box-sizing: border-box
  position: relative
  font-family: $ff-primary
  color: $fill-black
  background-color: $background-light
  +typoTextMd
  +scrollbarStyle
  scroll-behavior: smooth
  &.w-noscroll
    overflow: hidden
  &.w-noscrollnav
    +res-md-max
      overflow: hidden
  