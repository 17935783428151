@import styles/sass/abstracts

.root
  padding: 2.4rem
  border: 1px solid $stroke-color
  +vSpace(2rem, 1.6rem)
  color: $text-black
  +res-sm-max
    padding: 1.6rem

.title
  +typoBase(8rem, 1.3, $fw-extrabold, $ff-secondary)
  +res-sm-max
    font-size: 5rem

.description
  +typoH4
  +res-sm-max
    +typoH6Mobile