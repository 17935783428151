@import styles/sass/abstracts

.root
  display: flex
  align-items: flex-start
  gap: 2rem
  +res-sm-max
    gap: 1.6rem
  
.imageBox
  display: block
  width: 10rem
  +res-sm-max
    width: 6.4rem
    height: 6.4rem

.main
  flex: 1
  +res-md-min
    display: flex
    align-items: flex-end
    justify-content: space-between
    gap: 1.6rem
  +res-sm-max
    +vSpace(.8rem)
    
.content
  +vSpace(.8rem)

.category
  display: block
  +typoTextSm
  text-transform: uppercase
  color: $text-grey-dark
  +res-md-min
    font-weight: $fw-medium
  +res-sm-max
    +typoTextXs

.title
  display: block
  +typoTextLg
  font-weight: $fw-semibold

.details
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: .8rem 2rem
  +res-sm-max 
    justify-content: space-between

.attribute
  +typoTextXs

.attributeValue
  font-weight: $fw-semibold

.attributeField
  display: flex
  align-items: center
  gap: 1.2rem

.attributeFieldLabel
  +typoTextSm

.attributeFieldDropdown
  min-width: 8rem

.quantityCounter
  max-width: 12rem

.actions
  display: flex
  align-items: center
  gap: 1.6rem
  &--desktop
    +res-sm-max
      display: none
  &--mobile
    gap: 1.2rem
    margin-left: auto
    +res-md-min
      display: none

.pricesBox
  +vSpace(.4rem)
  +res-md-min
    text-align: right

.prices
  +vSpace(.4rem)
  +typoTextXs

.price
  white-space: nowrap
  &:not(.price--current)
    color: $text-grey-dark
    .priceValue
      text-decoration: line-through
  &--current
    +typoTextLg
    font-weight: $fw-semibold
