@import styles/sass/abstracts

.option
  position: relative
  padding: 2rem 1.6rem
  border: 1px solid $stroke-color
  background: $fill-white
  color: $text-black
  text-align: center
  display: flex
  flex-direction: column
  justify-content: center
  +res-sm-max
    padding: 1.5rem 1.2rem
  &:not(.option--disabled)
    cursor: pointer
  &--checked
    background: $fill-neutral-30

.optionMain
  +vSpace(.4rem, .2rem)

.optionInput
  position: absolute
  left: -9999px
  &:focus-visible
    + .optionStyledInput
      &::before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        outline: 2px solid $fill-black
        pointer-events: none
      

.optionLabel
  +typoTextMd
  font-weight: $fw-semibold

.optionDescription
  +typoTextXs
  +customContent

.options
  display: flex
  flex-wrap: wrap
  justify-content: center
  gap: 1.6rem
  > *
    width: calc(25% - 1.2rem)
  +res-sm-max
    gap: 1.2rem
    > *
      width: calc(50% - .6rem)
  > *
    &:first-child:nth-last-child(odd):not(:nth-last-child(1)), &:first-child:nth-last-child(odd):not(:nth-last-child(1)) ~ * 
      +res-md-min
        width: calc(33.33% - 1.07rem)
      +res-xs-max
        width: 100%

