@import styles/sass/abstracts

.root
  padding-top: 4rem
  +res-sm-max
    padding-top: 2rem

.layout
  +vSpace(4rem, 2rem)

.main
  +vSpace(2.4rem, 2rem)

.tags
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 1.6rem

.time
  display: flex
  align-items: center
  gap: .8rem
  +typoTextSm

.timeIcon
  svg
    +styleIcon(2.4rem, $fill-black)
    
.content
  +vSpace(2.4rem, 1.2rem)

.title
  +typoH1
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextXl
  +customContent
  +res-sm-max
    +typoTextMd
