@import styles/sass/abstracts

.root
  padding: 1.2rem
  border: 1px solid $stroke-color
  color: $text-grey-dark
  display: block

  &--selected
    background: $fill-neutral-30
    border-color: $fill-black
    color: $text-black
