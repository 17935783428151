@import styles/sass/abstracts

.root
  background-color: $background-light
  color: $text-black
  height: 100dvh
  width: $menu-panel-width
  display: none
  position: fixed
  top: 0
  left: 0
  +scrollbarStyle
  overflow: auto
  z-index: 10
  +res-menu-desktop-min
    // after invece che border-radius per motivi di calcoli di spazi (verrebbe aggiunto 1px per ogni panel)
    &::after
      content: ""
      position: absolute
      top: 0
      right: 0
      height: 100%
      width: 1px
      background: $stroke-color
  +res-menu-mobile-max
    width: 100%
    max-width: 60rem
    top: $menu-mobile-head-height
    height: calc(100dvh - $menu-mobile-head-height)

  &--open
    display: block
  &--level0
    +res-menu-desktop-min
      display: none
  &--level2
    background: $fill-neutral-20
    +res-menu-desktop-min
      left: $menu-panel-width

.head
  display: flex
  align-items: center
  gap: 1.6rem
  border-bottom: 1px solid $stroke-color
  padding: 4rem 4rem 2rem
  +res-menu-mobile-max
    padding: 2rem $wcont-padding

.back, .close, .closeMobile
  svg
    +styleIcon(2.4rem, $fill-black)

.back
  +res-menu-desktop-min
    display: none

.title
  +typoH5
  flex: 1

.close
  +res-menu-mobile-max
    display: none

.body
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color

.block
  padding: 2rem 4rem
  +vSpace(1.6rem)
  +res-menu-mobile-max
    padding: 2rem $wcont-padding

  &--categories
    +vSpace(.8rem)

  &--promo, &--prodotti, &--news, &--video
    padding-left: 2rem
    padding-right: 2rem

.blockTitle
  +typoTextXs
  font-weight: $fw-semibold
  text-transform: uppercase
  color: $text-grey-dark

.categories
  +vSpace(.8rem)

.list
  +vSpace(1.6rem)

.promoList
  +vSpace(1.6rem) // inventato

.prodottiList, .newsList, .videoList
  +vSpace(4.1rem)
  > *
    position: relative
    &:not(:last-child)
      &::after
        content: ""
        position: absolute
        bottom: -2rem
        height: 1px
        left: 0
        width: 100%
        background: $stroke-color

.slider
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  margin-left: #{$wcont-padding * -1}
  margin-right: #{$wcont-padding * -1}
  \:global(.swiper-slide)
    width: auto!important

.langDropdown
  display: flex
  gap: 1rem
  padding: 0.6rem 1.6rem
  align-items: center
