@import styles/sass/abstracts

.root
  background: $fill-neutral-20
  color: $text-black
  border-top: 1px solid $stroke-color
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color
  @media print
    display: none!important

.topLayout
  +res-md-min
    display: flex
    align-items: center
    justify-content: space-between
    padding: 2rem 0
  +res-sm-max
    > *
      padding: 2rem 0

.logo
  display: block
  width: fit-content
  svg
    display: block
    width: 10rem
    height: 3.5rem

.socialMenu
  display: flex
  align-items: center
  +res-md-min
    gap: 4rem
  +res-sm-max
    justify-content: space-between
    border-top: 1px solid $stroke-color
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}
    padding-left: $wcont-padding
    padding-right: $wcont-padding

.socialItem
  +typoTextSm
  display: flex
  align-items: center
  gap: .8rem
  @media screen and (max-width: 360px)
    +typoTextXs

.socialItemIcon
  svg
    +styleIcon(2.4rem, currentColor)
    @media screen and (max-width: 360px)
      +sizeIcon(2rem)

.main
  padding: 4rem 0
  +res-sm-max
    padding: 0

.mainLayout
  +res-md-min
    display: flex
    flex-wrap: wrap
    gap: 2.4rem
    > *
      width: calc(25% - 2.8rem)
  +res-sm-max
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}

.col
  +vSpace(4rem, 0)
  
.col, .col > *
  +res-sm-max
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color

.menu
  position: relative
  +vSpace(2rem, 0)
  &--open
    .menuTitleIcon
      svg
        transform: rotate(180deg)
    .menuPanel
      max-height: 100rem
      visibility: visible

.menuTitleTrigger
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  padding: 2rem $wcont-padding
  &:focus-visible
    outline: none
    &::after
      content: ""
      pointer-events: none
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      +focusVisibleStd
      outline-offset: 0
  +res-md-min
    display: none

.menuTitleIcon
  svg
    +styleIcon(2.4rem, $fill-black)

.menuTitleLabel
  display: block
  +typoTextSm
  font-weight: $fw-semibold
  text-transform: uppercase
  &--desktop
    +res-sm-max
      display: none

.menuPanel
  +res-sm-max
    max-height: 0
    transition: max-height .3s
    overflow: hidden
    visibility: hidden

.menuList
  +vSpace(2rem, 1.6rem)
  +res-sm-max
    padding: .6rem $wcont-padding 2rem

.menuItem
  display: block
  +typoTextSm

.filiali
  +res-sm-max
    padding: 2rem $wcont-padding

.bottom
  +typoTextXs
  +customContent
  text-align: center
  color: $text-grey-dark
  padding: 3.3rem 0
