@import styles/sass/abstracts

=buttonIconSm
  padding: .2rem
  .icon, .loader
    svg
      +sizeIcon(2rem)

=buttonIconMd
  padding: .6rem
  .icon, .loader
    svg
      +sizeIcon(2rem)

=buttonIconLg
  padding: .8rem
  .icon, .loader
    svg
      +sizeIcon(2.4rem)

=buttonIconXl
  padding: 1.6rem
  .icon, .loader
    svg
      +sizeIcon(2.4rem)

.root
  position: relative
  display: inline-block
  border-radius: 50%
  transition: background .3s, color .3s
  &:focus-visible
    +focusVisibleStd
    outline-offset: .2rem
    .icon
      svg
        transform: scale(1.2)

  &--transparent
    background: none
    color: $fill-black
    &:hover:not(:disabled)
      background: $fill-primary-80
      color: $text-white
    &:disabled
      background: $fill-neutral-30
      color: $text-grey-dark

  &--black
    background: $fill-black
    color: $fill-white
    &:hover:not(:disabled)
      background: $fill-grey-dark
    &:disabled
      background: $fill-neutral-30
      color: $text-grey-dark

  &--neutral
    background: $fill-neutral-30
    color: $fill-black
    &:hover:not(:disabled)
      background: $fill-primary-80
      color: $text-white
    &:disabled
      color: $text-grey-dark

  &--primary
    background: $fill-primary-70
    color: $fill-white
    &:hover:not(:disabled)
      background: $fill-primary-80
    &:disabled
      background: $fill-neutral-30
      color: $text-grey-dark

  &--loading
    .icon
      opacity: 0

  &--sm
    +buttonIconSm
  &--md
    +buttonIconMd
  &--lg
    +buttonIconLg
  &--xl
    +buttonIconXl
    
.icon
  svg
    display: block
    transition: transform .3s
    +colorIcon(currentColor)

.loader
  position: absolute
  left: 0
  right: 0
  margin: 0 auto
  top: 50%
  transform: translateY(-50%)
  svg
    animation: rotation 1s infinite linear
