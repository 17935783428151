@import styles/sass/abstracts

.root
  display: inline-flex
  align-items: center
  gap: .8rem
  background: $fill-secondary-90
  color: $text-white
  padding: .5rem 1.2rem
  border-radius: 2rem
  +typoTextSm

.close
  svg
    +styleIcon(2rem, $fill-white)
