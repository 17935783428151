@import styles/sass/abstracts

.root
  color: $text-black
  border-bottom: 1px solid $stroke-color
  background: $background-light
  +maxResBounds
  &--image
    +res-md-min
      display: flex
      gap: 10.8rem
      .main
        padding-left: $wcont-padding
        max-width: 66.8rem
        margin-left: auto
        flex: 1
      .imageBox
        width: calc(50% - 10.8rem)

    +res-sm-max
      .main
        padding-left: $wcont-padding
        padding-right: $wcont-padding
    

.imageBox
  height: 40rem
  +res-sm-max 
    height: 20rem
  img
    height: 100%      
    
.main
  padding: 4rem 0
  +vSpace(4rem)
  +res-sm-max
    padding: 2rem 0

.content
  +vSpace(2rem)
  +res-md-min
    max-width: 79.2rem

.title
  +typoH1
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextMd
  +customContent
  +res-sm-max 
    +typoTextSm