@import styles/sass/abstracts


.root
  position: relative
  display: flex
  align-items: flex-start
  width: 100%
  gap: 2rem
  color: $text-black
  &--md
    +res-md-min
      align-items: center
      .main
        display: flex
        gap: 2rem
        +vSpace(0)

      .content
        flex: 1

      .imageBox
        width: 12rem
        height: 12rem
        

.imageBox
  width: 10rem
  height: 10rem
  position: relative
  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(#000, .24)
    z-index: 1

.play
  position: absolute
  transform: translateX(-50%) translateY(-50%)
  top: 50%
  left: 50%
  z-index: 2
  svg
    +styleIcon(4rem, $fill-white)
    +res-sm-max
      +sizeIcon(3.2rem)
    

.main
  +vSpace(.8rem)
  flex: 1

.content
  +vSpace(.8rem)

.title
  +typoTextLg
  font-weight: $fw-semibold

.description
  +typoTextSm
  color: $text-grey-dark

  
.cta
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1