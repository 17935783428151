@import styles/sass/abstracts


.root
  position: relative
  display: flex
  align-items: flex-start
  width: 100%
  gap: 2rem
  color: $text-black
  &--md
    +res-md-min
      align-items: center
      .main
        display: flex
        align-items: center
        gap: 2rem
        +vSpace(0)

      .content
        flex: 1

      .imageBox
        display: block
        width: 12rem
        height: 12rem
        
      .prices
        text-align: right
        +vSpace(.8rem)

.imageBox
  display: none

.main
  +vSpace(.8rem)
  flex: 1

.content
  +vSpace(.8rem)

.title
  +typoTextLg
  font-weight: $fw-semibold

.description
  +typoTextSm
  color: $text-grey-dark
  
.cta
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1