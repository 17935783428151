@import styles/sass/abstracts

.root
  padding: 8rem 0
  color: $text-black
  +maxResBounds
  +res-sm-max
    padding: 4rem 0
  > :global(.block-card-group)
    margin-top: 4rem
    padding: 2rem 0
    +vSpace(4rem)
  > :global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi Tab attivita block"

  \:global(.block-tab-attivita-block)
    padding: 2rem 0
    \:global(.block-card-group--empty)
      &::before
        content: "Trascina qui gli elementi Attività card"
    > *
      display: flex
      flex-wrap: wrap
      justify-content: center
      gap: .4rem
      > *
        width: 34rem

.layout
  +vSpace(4rem)

.main
  +vSpace(1.2rem)
  max-width: 86.4rem
  margin-left: auto
  margin-right: auto
  text-align: center

.title
  +typoH2
  +res-sm-max
    +typoH4Mobile

.description
  +typoTextXl
  +customContent
  +res-sm-max
    +typoTextMd
    
.slider
  display: none
  margin-top: 4rem
  \:global(.swiper-slide)
    box-sizing: border-box
    width: 34rem
    +res-sm-max
      width: 30rem
  \:global(.swiper-pagination)
    margin-left: $wcont-padding
    margin-top: 6.4rem
    +res-xl-min
      margin-left: 7.2rem
    +res-sm-max
      max-width: calc(100% - 2 * $wcont-padding)
      margin-top: 2.4rem
  \:global(.swiper-button-next)
    margin-right: $wcont-padding
    margin-top: 4rem
    +res-xl-min
      margin-right: 7.2rem
  &--visible
    display: block