@import styles/sass/abstracts

.root
  position: relative
  +typoTextMd
  color: $text-black
  &--open
    .trigger
      box-shadow: 0 0 0 1px $fill-black
      border-color: $fill-black
    .menu
      display: block
  &--top
    .menu
      top: auto
      bottom: 100%
      margin-bottom: .8rem
      margin-top: 0

.label 
  +typoTextXs
  margin-bottom: .4rem

.trigger
  display: flex
  justify-content: space-between
  align-items: center
  gap: 2rem
  padding: .7rem 1.1rem
  background: $fill-white
  border-radius: .8rem
  border: 1px solid $stroke-color
  transition: box-shadow .3s, border-color .3s, background .3s, color .3s
  width: 100%
  text-align: left
  +typoTextSm
  &:disabled
    background: $fill-neutral-30
    color: $text-grey-dark

.triggerLabel
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  color: $text-grey-dark
  
.arrow
  svg
    +styleIcon(2.4rem, $fill-black)

.menu
  position: absolute
  top: 100%
  left: 0
  width: 100%
  max-height: 18rem
  overflow-y: auto
  margin-top: .8rem
  border-radius: .8rem
  border: 1px solid $stroke-color
  background: $fill-white
  display: none
  z-index: 1
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color

.option
  padding: 1.2rem
  display: block
  width: 100%
  text-align: left
  +typoTextSm
  &:hover
    background: $fill-neutral-20
  &:focus-visible
    outline-offset: -2px
  &--selected
    font-weight: $fw-semibold


