@import styles/sass/abstracts

.root
  background: $fill-secondary-90
  color: $text-white
  padding: 2.4rem
  +vSpace(2rem)

.content
  +vSpace(1.2rem)

.title
  +typoH4
  
.description
  +typoTextSm
  +customContent