@import styles/sass/abstracts

.root
  border-bottom: 1px solid $stroke-color
  \:global(.swiper)
    +widen($wcont-padding)
  \:global(.swiper-slide)
    width: auto!important
  \:global(.swiper-button-prev), :global(.swiper-button-next)
    top: 0
    width: 10.6rem
    height: 100%
    padding: 0
    border-radius: 0
    &::after
      position: absolute
      width: 3.2rem
      height: 3.2rem
      top: calc(50% - 1.6rem)
      padding: .6rem
      background: $fill-neutral-30
      border-radius: 50%

    &:global(.swiper-button-disabled)
      display: none

  \:global(.swiper-button-prev)
    background: linear-gradient(270deg, rgba($background-light-r, $background-light-g, $background-light-b, 0) 0%, $background-light 75%)
    left: 0
    &::after
      left: 0
    +res-md-max
      display: none

  \:global(.swiper-button-next)
    background: linear-gradient(90deg, rgba($background-light-r, $background-light-g, $background-light-b, 0) 0%, $background-light 75%)
    right: 0
    &::after
      right: 0
    +res-md-max
      display: none

  &--neutrallight
    \:global(.swiper-button-prev)
      background: linear-gradient(270deg, rgba($fill-neutral-20-r, $fill-neutral-20-g, $fill-neutral-20-b, 0) 0%, $fill-neutral-20 75%)
    \:global(.swiper-button-next)
      background: linear-gradient(90deg, rgba($fill-neutral-20-r, $fill-neutral-20-g, $fill-neutral-20-b, 0) 0%, $fill-neutral-20 75%)


  &--boxed
    \:global(.swiper)
      +res-lg-min
        padding-left: 0
        padding-right: 0
