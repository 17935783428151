
.ROOT
  &--editing  
    .simple-text-editor
      border: none
      outline: none
      appearance: none
      font-family: inherit
      font-size: inherit
      font-weight: inherit
      line-height: inherit
      letter-spacing: inherit
      color: inherit
      padding: 0
      width: 100%
      background: none
      resize: none