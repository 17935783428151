@import styles/sass/abstracts

.root
  padding: 1.1rem 1.9rem
  border: 1px solid $stroke-color
  color: $text-black
  +typoTextLg
  +res-sm-max
    padding: .9rem 1.5rem
    +typoTextMd

  &--selected
    background: $fill-black
    border-color: $fill-black
    color: $text-white
    font-weight: $fw-semibold

  &:not(.root--selected)
    text-decoration: underline
