@import styles/sass/abstracts

.root
  &--narrow
    .main
      +res-lg-min
        max-width: 71.5rem

.layout
  +res-lg-min
    display: flex
    gap: 4rem

.sidebar
  width: 25%
  max-width: 34.5rem
  +res-md-max
    display: none

.main
  padding: 4rem 0
  +vSpace(2.4rem)
  +res-lg-min
    flex: 1
  +res-sm-max
    padding: 2rem 0

.back
  &--onlyMobile
    +res-lg-min
      display: none

.content
  +vSpace(2.4rem)

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.subtitle
  +typoTextMd

.description
  color: $text-grey-dark
  +typoTextSm

.subtitle, .description
  +customContent
  a
    color: $text-primary-70
    font-weight: $fw-semibold
