@import styles/sass/abstracts

.root
  padding: 2rem 0
  &:first-child
    padding-top: 0

.title
  +typoTextSm
  font-weight: $fw-medium
  color: $text-grey-dark
  text-transform: uppercase
  margin-bottom: 2rem