@import "../../styles/sass/abstracts"

.ROOT
  &--editing
    .block-ricerca-prodotto-card
      &--empty, &--loading, &--error
        &::before
          min-height: 12rem
          border: 1px dashed $stroke-color
          padding: 2rem
          display: flex!important
          align-items: center
          justify-content: center
      &--empty
        &::before
          content: "Seleziona il prodotto"
          +typoPagebuilderHint
      &--error
        &::before
          content: "Errore nel caricamento del prodotto"
          +typoPagebuilderHint
          color: $text-error
      &--loading
        &::before
          content: "Loading..."
          +typoPagebuilderHint