@import styles/sass/abstracts

.root
  position: fixed
  top: 0
  left: 0
  right: 0
  height: $header-height
  background: $background-light
  border-top: 1px solid $stroke-color
  z-index: 3000
  &--searchOpen
    +res-menu-desktop-min
      display: none
  +res-menu-mobile-max
    border-bottom: 1px solid $stroke-color
    height: $header-height-mobile
  > *
    +res-menu-desktop-min
      border-bottom: 1px solid $stroke-color
  @media print
    display: none!important

.top
  padding: .4rem 0
  height: $header-top-height
  +typoTextSm
  font-weight: $fw-medium
  +res-menu-mobile-max
    display: none

.topLayout
  display: flex
  gap: 2rem

.utils, .utilsMenu, .topMenu, .mainList, .nav > ul
  display: flex
  align-items: center
  gap: 3.2rem
  +res-menu-mobile-max
    gap: .8rem
  +res-menu-desktop-min
    > *
      &:not(:last-child)
        position: relative
        &::after
          content: ""
          position: absolute
          top: 0
          width: 1px
          right: -1.5rem
          height: 100%
          background: $stroke-color

.topMenu
  margin-left: auto

.topMenuItem
  &--green
    color: $text-idee-verdi

.main
  padding: .8rem 0
  height: $header-main-height
  +res-menu-mobile-max
    padding: 1.6rem 0
    height: $header-main-height-mobile

.mainLayout
  display: flex
  align-items: center
  justify-content: space-between

.logo
  display: block
  svg
    display: block
    width: 13rem
    height: 4.5rem
    +res-menu-mobile-max
      width: 10rem
      height: 3.5rem

.mainList, .mainActions
  width: calc(50% - 6.5rem)

.nav > ul
  gap: 4.8rem
  +res-menu-mobile-max
    gap: 1.6rem
  > *
    &:not(:last-child)
      &::after
        right: -2.3rem

.nav > ul > li > ul
  display: flex
  align-items: center
  gap: 2.4rem
  +res-menu-mobile-max
    display: none

.mainActions
  display: flex
  align-items: center
  justify-content: flex-end
  gap: .8rem

.menuTriggerMobile, .profileTriggerMobile, .searchMobileBox
  +res-menu-desktop-min
    display: none

.profileTriggerDesktop, .searchDesktop
  +res-menu-mobile-max
    display: none

.searchDesktop
  width: 25rem

.searchMobileBox
  padding-bottom: 1rem

.searchMobile
  width: 100%

\:global(.preheader  ~ .page)
  .root
    +res-menu-desktop-min
      top: $preheader-height
    +res-menu-mobile-max
      top: $preheader-height
      +res-sm-max
        top: $preheader-height-mobile


\:global(.toolbar  ~ .page)
  .root
    +res-menu-desktop-min
      top: $toolbar-height
    +res-menu-mobile-max
      top: $toolbar-height

\:global(.toolbar ~ .preheader  ~ .page)
  .root
    +res-menu-desktop-min
      top: #{$preheader-height + $toolbar-height}
    +res-menu-mobile-max
      top: #{$preheader-height + $toolbar-height}
      +res-sm-max
        top: #{$preheader-height-mobile + $toolbar-height}

.cartIconDesktop
  +res-menu-mobile-max
    display: none

.cartIconMobile
  display: none
  +res-menu-mobile-max
    display: block
