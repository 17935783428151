@import styles/sass/abstracts
// Copia di quello di bootstrap originale con degli adattamenti

$tooltip-bg: $fill-black
$tooltip-arrow-size: .6rem

.root
  position: absolute
  z-index: 1070
  display: block
  margin: 0
  text-align: left
  text-align: start
  letter-spacing: normal
  word-break: normal
  word-spacing: normal
  white-space: normal
  line-break: auto
  word-wrap: break-word
  opacity: 0

  &:global(.show)
    opacity: 1

  \:global(.arrow)
    position: absolute
    display: block
    width: #{$tooltip-arrow-size * 2}
    height: $tooltip-arrow-size
    &::before
      position: absolute
      content: ""
      border-color: transparent
      border-style: solid

  &:global(.bs-tooltip-auto[x-placement^=top]), &:global(.bs-tooltip-top)
    padding: $tooltip-arrow-size 0
    \:global(.arrow)
      bottom: 0
      &::before
        top: 0
        border-width: $tooltip-arrow-size $tooltip-arrow-size 0
        border-top-color: $tooltip-bg 

  &:global(.bs-tooltip-auto[x-placement^=right]), &:global(.bs-tooltip-right)
    padding: 0 $tooltip-arrow-size
    \:global(.arrow)
      left: 0
      width: $tooltip-arrow-size
      height: #{$tooltip-arrow-size * 2}
      &::before
        right: 0
        border-width: $tooltip-arrow-size $tooltip-arrow-size $tooltip-arrow-size 0
        border-right-color: $tooltip-bg 

  &:global(.bs-tooltip-auto[x-placement^=bottom]), &:global(.bs-tooltip-bottom) 
    padding: $tooltip-arrow-size 0
    \:global(.arrow)
      top: 0
      &::before
        bottom: 0
        border-width: 0 $tooltip-arrow-size $tooltip-arrow-size
        border-bottom-color: $tooltip-bg 

  &:global(.bs-tooltip-auto[x-placement^=left]), &:global(.bs-tooltip-left)
    padding: 0 $tooltip-arrow-size
    \:global(.arrow)
      right: 0
      width: $tooltip-arrow-size
      height: #{$tooltip-arrow-size * 2}
      &::before
        left: 0
        border-width: $tooltip-arrow-size 0 $tooltip-arrow-size $tooltip-arrow-size
        border-left-color: $tooltip-bg 

  \:global(.tooltip-inner)
    max-width: 40rem
    min-width: 10rem
    text-align: center
    background-color: $tooltip-bg 
    color: $text-white
    border-radius: .8rem
    +typoTextSm
    padding: 1.2rem

.content
  +customContent