@import styles/sass/abstracts

.root
  padding: 1rem 0
  width: 100%
  &:focus-visible
    outline: none
    &::after
      +focusVisibleStd
  &::after
    content: ""
    display: block
    width: 5.6rem
    height: .4rem
    margin: 0 auto
    background: $fill-grey-dark
    border-radius: 2rem
