@import styles/sass/abstracts

.root
  +maxResBounds
  +res-lg-min
    display: flex 
    gap: 2rem

.sidebar
  border-right: 1px solid $stroke-color
  width: calc(30.4rem + #{($res-max - $wcont-width-md-px) * .5})
  @media screen and (max-width: $res-max)
    width: calc(30.4rem + 50vw - #{$wcont-width-md-px * .5})
  @media screen and (max-width: $wcont-width-md-px)
    width: 30.4rem 
  +res-md-max
    display: none

.filtersBlock
  &:not(:last-child)
    border-bottom: 1px solid $stroke-color

.filtersItem, .filtersAdvancedBox
  max-width: 30.4rem
  margin-left: auto

.filtersItem
  padding: 1.6rem $wcont-padding

.filtersAdvancedBox
  padding: 2rem $wcont-padding

.main
  padding: 2rem $wcont-padding 4rem
  max-width: #{97.2rem + $wcont-padding * 2}
  +vSpace(2rem)
  +res-lg-min
    flex: 1
  +res-md-max
    padding: 0 $wcont-padding 2rem

.top
  +res-lg-min
    display: flex
    align-items: flex-end
    justify-content: space-between
  +res-md-max
    padding: 1.2rem $wcont-padding
    +widen($wcont-padding)
    border-bottom: 1px solid $stroke-color

.utils
  display: flex
  align-items: flex-end
  gap: 2rem
  &--desktop
    +res-md-max
      display: none
  &--mobile
    align-items: center
    justify-content: space-between
    +res-lg-min
      display: none

.order
  width: 23rem

.num
  +typoTextSm
  color: $text-grey-dark
  &--desktop
    +res-md-max
      display: none
  &--mobile
    +res-lg-min
      display: none

.selectedFilters
  +res-lg-min
    display: flex
    justify-content: space-between
    gap: 2rem
  +res-md-max
    +vSpace(2rem)

  &--noFilters
    // Nella mobile va lasciato perchè contiene il numero dei prodotti
    +res-lg-min
      display: none

.selectedFiltersActions
  order: 999
  margin-left: auto
  +res-md-max
    display: flex
    align-items: center
    justify-content: space-between
    gap: 2rem

.selectedFiltersReset
  +typoTextSm
  color: $text-grey-dark
  text-decoration: underline
  white-space: nowrap


.selectedFiltersList
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: .8rem
  &--desktop
    +res-md-max
      display: none
  &--mobile
    +res-lg-min
      display: none

.selectedFiltersSwiper
  margin-left: #{$wcont-padding * -1}
  margin-right: #{$wcont-padding * -1}
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  \:global(.swiper-slide)
    width: auto!important

.list
  +res-md-min
    display: flex
    flex-wrap: wrap
    > *
      width: 50%
      +res-xl-min
        width: 33.33%
        &:not(:nth-child(3n + 1))
          border-left: none
        // &:nth-child(n+4)
        //   border-top: none
      +res-lg-max
        &:not(:nth-child(2n + 1))
          border-left: none
        // &:nth-child(n+3)
        //   border-top: none
  +res-sm-max
    +vSpace(2rem)
    > *
      max-width: 40rem
      margin-left: auto
      margin-right: auto

.listItem
  margin-bottom: 2rem

.loadMore
  text-align: center