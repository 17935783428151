// Copia di quello di bootstrap originale con degli adattamenti
.pb-tooltip
  position: absolute
  z-index: 100001 // leftSidebar panel ha 100000
  display: block
  margin: 0
  text-align: left
  text-align: start
  letter-spacing: normal
  word-break: normal
  word-spacing: normal
  white-space: normal
  line-break: auto
  word-wrap: break-word
  opacity: 0
  &.show
    opacity: .9

  .arrow
    position: absolute
    display: block
    width: 10px
    height: 5px
    &::before
      position: absolute
      content: ""
      border-color: transparent
      border-style: solid

  .bs-tooltip-auto[x-placement^=top], .bs-tooltip-top
    padding: 5px 0
    .arrow
      bottom: 0
      &::before
        top: 0
        border-width: 5px 5px 0
        border-top-color: $pb-grey-80

  .bs-tooltip-auto[x-placement^=right], .bs-tooltip-right
    padding: 0 5px
    .arrow
      left: 0
      width: 5px
      height: 10px
      &::before
        right: 0
        border-width: 5px 5px 5px 0
        border-right-color: $pb-grey-80

  .bs-tooltip-auto[x-placement^=bottom],.bs-tooltip-bottom
    padding: 5px 0
    .arrow
      top: 0
      &::before
        bottom: 0
        border-width: 0 5px 5px
        border-bottom-color: $pb-grey-80

  .bs-tooltip-auto[x-placement^=left],.bs-tooltip-left
    padding: 0 5px
    .arrow
      right: 0
      width: 5px
      height: 10px
      &::before
        left: 0
        border-width: 5px 0 5px 5px
        border-left-color: $pb-grey-80

  .tooltip-inner
    max-width: 200px
    text-align: center
    // Custom
    background-color: $pb-grey-80
    color: $pb-grey-20
    border-radius: 4px
    +typoTextMd
    font-family: $pb-font-primary
    padding: 8px 16px