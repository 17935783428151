@import styles/sass/abstracts

.root
  display: inline-flex
  align-items: center
  gap: .4rem
  background: $fill-black
  color: $text-white
  font-weight: $fw-medium
  white-space: nowrap
  &--sm
    +typoTextXs
    padding: .3rem .6rem
    .icon
      svg
        +sizeIcon(1.6rem)

  &--lg
    +typoTextSm
    padding: .4rem .8rem
    .icon
      svg
        +sizeIcon(2rem)

  &--secondarydark
    background: $fill-secondary-90

  &--white
    background: $fill-white
    color: $text-black
    
  &--whiteborder
    background: $fill-white
    color: $text-black
    border: 1px solid $stroke-color
    &.root
      &--sm
        padding: .2rem .5rem
      &--lg
        padding: .3rem .7rem

  &--promo
    background: $fill-accent-1
    color: $text-black

  &--ideeverdi
    background: $fill-idee-verdi

  &--novita
    background: $fill-primary-70

  &--categoria
    background: $fill-neutral-30
    color: $text-black
    
.icon
  svg
    display: block
    +colorIcon(currentColor)
