@import styles/sass/abstracts

.root
  padding: 8rem 0
  color: $text-black
  +res-md-max
    padding: 4rem 0

.layout
  +res-md-min
    display: flex
    align-items: center
    gap: 8rem
    > *
      width: calc(50% - 4rem)
  +res-sm-max
    +vSpace(4rem)

.main
  +vSpace(2.4rem)

.content
  +vSpace(2.4rem)

.title
  +typoH1
  +res-md-max
    +typoH3Mobile

.description
  +typoTextXl
  +customContent
  +res-md-max 
    +typoTextMd

.imageBox
  height: 50rem
  +res-sm-max
    height: 27rem