@import styles/sass/abstracts

.root
  +vSpace(1.6rem, 1.2rem)
  @media print
    page-break-inside: avoid
  &--noecommerce
    .main
      &:not(:last-child)
        border-bottom: none
        padding-bottom: 0

.main
  +vSpace(1.6rem, 1.2rem)
  &:not(:last-child)
    padding-bottom: 1.6rem
    border-bottom: 1px solid $stroke-color

.head
  display: flex
  align-items: center
  gap: 2rem

.utilizzoDesktop
  margin-left: auto
  +res-md-max
    display: none

.utilizzoMobile
  display: none
  +res-md-max
    display: inline-block
    margin-bottom: 1.2rem

.category
  display: flex
  flex-wrap: wrap
  gap: .4rem
  font-weight: $fw-medium
  text-transform: uppercase
  +typoTextSm
  color: $text-grey-dark

  &--battery
    color: $text-batteria

.categoryIcon
  svg
    +styleIcon(2.4rem, $fill-batteria)

.title
  +typoH4
  color: $text-black
  +res-sm-max
    +typoH6Mobile

.description
  +typoTextSm
  +customContent
  color: $text-grey-dark

.variants
  +vSpace(1.6rem, 1.2rem)
  &--dropdown
    .variantsTitle
      +typoTextXs
  &--list
    .variantsTitle
      > *
        display: inline
    .variantsTitleValue
      font-weight: $fw-bold

.variantsLayout
  display: flex
  flex-wrap: wrap
  align-items: flex-end
  justify-content: space-between
  gap: 2.4rem
    

.variantsBox
  +vSpace(.4rem)
  max-width: 35rem
  +res-sm-min
    flex: 1
  +res-xs-max
    width: 100%

.guidaTaglie
  margin-left: auto
  &--desktop
    +res-xs-max
      display: none
  &--mobile
    +res-sm-min
      display: none

.variantsList
  +res-sm-lg-min
    display: flex
    gap: .8rem
    > .variantsListItem
      width: calc(33.33% - .5333rem)
  +res-xs-md
    +vSpace(.8rem)

.pricesLayout
  +res-xs-max
    display: flex
    align-items: flex-start
    justify-content: space-between

.prices
  +vSpace(.6rem, .4rem)
  +typoTextSm
  color: $text-grey-dark

.price
  display: block
  &--current
    // Ci può essere la Tag della promo dipendenti accanto al current
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: .4rem .8rem
    .priceValue
      +typoH3
      color: $text-black
      +res-sm-max
        +typoH4Mobile
  &--listino, &--prev
    .priceValue
      text-decoration: line-through

.ctaBox
  display: flex
  align-items: center
  gap: 1.6rem

.cta
  +res-xs-max
    flex: 1

.disponibilitaMessage
  +typoTextSm
  color: $text-grey-dark
  table
    border-spacing: 0
    border-collapse: collapse
  th, td
    border: 1px solid $stroke-color
    padding: .5rem
  &--noecommerce
    overflow-x: auto
    +horizontalScrollbarStyle
    padding-bottom: 1.6rem

.actionsWrap
  +vSpace(1.6rem)

.actions
  +res-sm-lg-min
    display: flex
    align-items: center
    gap: 2.4rem
  +res-xs-md
    +vSpace(1.6rem)

.ecommerceActions
  +vSpace(1.6rem)

.alert
  display: flex
  align-items: center
  gap: 1.6rem
  +res-xs-md
    padding: .8rem 0

.alertIcon
  svg
    +styleIcon(3.2rem, $fill-black, $fill-error)

.alertLabel
  +typoTextMd
  font-weight: $fw-semibold
  color: $text-grey-dark

.noBuyLabel
  +typoTextSm
  color: $text-grey-dark

.bottomActions
  display: flex
  align-items: center
  justify-content: space-between
  +res-sm-lg-min
    margin-left: auto

.wishlist
  &--desktop
    +res-xs-md
      display: none
  &--mobile
    +res-sm-lg-min
      display: none