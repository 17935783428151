@import styles/sass/abstracts
  
.label
  display: flex
  align-items: center
  width: 100%
  color: $text-black
  margin-bottom: .4rem
  +typoTextXs
  gap: .4rem

.tooltip
  display: block
  svg
    +styleIcon(1.6rem, currentColor)

.children
  +res-md-min
    display: flex
    gap: 1.6rem
    > * 
      flex: 1 0 0 
  +res-sm-max
    > *
      &:not(:last-child)
        margin-bottom: 1.6rem

.root
  +res-sm-max
    width: 100%

  &--children-column-sm
    .children
      +vSpace(.8rem)
      +res-md-min
        display: block


  &--required
    &:not(.root--box)
      .labelValue
        &::after
          content: "*"

  &--box
    > .label
      +typoTextSm
      font-weight: $fw-semibold
      margin-bottom: 1.6rem
    .error
      margin-top: 1.6rem 

    &.root--space-md
      > .label
        margin-bottom: 2rem
 
  // layout
  &--layout-sm
    +res-md-min
      width: 33.33%
  &--layout-md
    +res-md-min
      width: 50%
  &--layout-lg
    +res-md-min
      width: 66.66%
  &--layout-full
    +res-md-min
      width: 100%