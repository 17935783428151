@import styles/sass/abstracts

.select
  \:global(.react-select__control)
    border: none!important
    border-radius: none!important
    background: none!important
    padding: 0!important
    gap: .8rem
  \:global(.react-select__control--is-focused)
    border-color: none!important
    box-shadow: none!important
    +focusVisibleStd

  \:global(.react-select__dropdown-indicator)
    svg
      +styleIcon(2.1rem, $fill-black)
  \:global(.react-select__value-container)
    padding: 0!important
    min-height: 2.1rem!important
  \:global(.react-select__input-container)
    margin: 0!important
    padding: 0!important

  \:global(.react-select__menu)
    min-width: 10rem

  &--top
    \:global(.react-select__menu)
      bottom: 100%
      top: auto
