@import styles/sass/abstracts

.root
  position: relative
  color: $text-black
  &:not(.root--main)
    .title
      +res-md-min
        +multilineEllipsis(2, 4.2rem)
    .description
      +res-md-min
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
      

  &--main
    +res-md-min
      background: $fill-neutral-30
      display: flex
      align-items: center
      gap: 4rem
      > *
        width: calc(50% - 2rem)
      .main
        padding-right: 4rem
      .imageBox
        height: 42.4rem

.main
  +vSpace(1.2rem)
  padding: 1.2rem 0

.imageBox
  height: 30rem
  +res-sm-max
    height: 20rem

.head
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: .8rem

.time
  display: flex
  align-items: center
  gap: .8rem
  margin-left: auto
  +typoTextSm

.timeIcon
  svg
    +styleIcon(2.4rem, $fill-black)

.title
  +typoH5
  +res-md-min
    +typoH3
  +res-sm-max
    +multilineEllipsis(2, 2.6rem)

.description
  +typoTextMd
  +res-sm-max
    +multilineEllipsis(2, 2.4rem)

.cta
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1