.bertolini-theme
  --stroke-color: #C1D0D1
  --background-light: #FFFFFF
  --background-light-r: 255
  --background-light-g: 255
  --background-light-b: 255
  --text-black: #161616
  --text-grey-dark: #5C6666
  --text-white: #fff
  --text-primary-70: #007DB3
  --text-primary-80: #0D6A92
  --text-idee-verdi: #0D6C38
  --text-disabled: #CEC4B4
  --text-error: #D10000
  --text-batteria: #006F9E
  --fill-black: #161616
  --fill-black-r: 22
  --fill-black-g: 22
  --fill-black-b: 22
  --fill-grey-dark: #5C6666
  --fill-white: #FFFFFF
  --fill-primary-70: #007DB3
  --fill-primary-80: #0D6A92 
  --fill-primary-40: #5EB3D8
  --fill-primary-10: #CFEAF6 
  --fill-secondary-90: #2E2623
  --fill-secondary-20: #C7BBB7    
  --fill-neutral-30: #ECF8FD
  --fill-neutral-20: #F8FDFF
  --fill-neutral-20-r: 248
  --fill-neutral-20-g: 253
  --fill-neutral-20-b: 255
  --fill-idee-verdi: #0D6C38
  --fill-success: #005229
  --fill-success-10: #E2E9D8
  --fill-error: #D10000
  --fill-error-10: #FFEBEB
  --fill-batteria: #006F9E
  --fill-accent-1: #BEEF7D
  --fill-disabled: #CEC4B4
  --border-primary-70: #007DB3
  --border-black: #161616
