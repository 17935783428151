@import styles/sass/abstracts

.root
  color: $text-white

.layout
  position: relative
  +res-md-min
    height: 40rem
  +res-sm-max
    padding: 4rem $wcont-padding
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}

.imageBox
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 0
  +res-md-min
    height: 40rem

.breadcrumbs
  +res-md-min
    position: absolute
    top: 4rem
    left: 7.2rem

.main
  position: relative
  z-index: 1
  +res-md-min
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    height: 40rem
    padding: 4rem 7.2rem
  +res-sm-max
    +vSpace(4rem)

.content
  +vSpace(2.4rem)
  +res-md-min
    max-width: 62.4rem

.title
  +typoH1
  +res-sm-max
    +typoH2Mobile

.description
  +typoTextMd
  +customContent
  +customContentDarkVariant