@import styles/sass/abstracts

.form, .applied
  display: flex
  justify-content: space-between
  gap: 1.6rem

.form 
  align-items: flex-start

.applied
  flex-wrap: wrap
  align-items: center

.fieldWrap
  flex: 1

.removeButton
  margin-left: auto
