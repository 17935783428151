@import styles/sass/abstracts

.layout
  > *
    &:not(:last-child)
      border-bottom: 1px solid $stroke-color

.list
  padding: 4rem 0
  +vSpace(2rem)
  +res-sm-max
    padding: 2rem 0

.ctaBox
  text-align: center
