@import styles/sass/abstracts

.root
  position: relative
  height: 40rem
  svg
    position: absolute
    top: calc(50% - 1.2rem)
    left: calc(50% - 1.2rem)
    +styleIcon(2.4rem, $fill-primary-70)
    animation: rotation 1s infinite linear