@import styles/sass/abstracts

.root
  padding: 2.4rem 
  border: 1px solid $stroke-color
  +vSpace(2.4rem)
  > *
    &:not(:last-child)
      padding-bottom: 2.4rem
      border-bottom: 1px solid $stroke-color
  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi TabellaDifferenzeCardItem"

.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.list, .root :global(.block-card-group)
  +vSpace(3.2rem)
