@import styles/sass/abstracts

.block-accordion
  border-top: 1px solid $stroke-color
  border-bottom: 1px solid $stroke-color
  padding: 2rem 0
  +res-sm-max
    padding: 2.4rem 0
  + .block-accordion
    border-top: 0
    margin-top: -4rem // compensa il margine del vSpace del container
    +res-sm-max
      margin-top: -2rem