@import styles/sass/abstracts

.root
  padding: 8rem 0
  +res-sm-max
    padding: 4rem 0
  +spacer
  +borderer
  \:global(.block-card-group)
    +res-lg-min
      display: flex
      flex-wrap: wrap
      margin-left: -1rem
      margin-right: -1rem
      gap: 2rem 0
      \:global(.block-text-image)
        padding-left: 1rem
        padding-right: 1rem
        > *
          height: 100%
        &:global(.block-text-image--100)
          flex-basis: 100%
        &:global(.block-text-image--66)
          flex-basis: 66.66%
          +res-md-max
            flex-basis: 100%
        &:global(.block-text-image--50)
          flex-basis: 50%
        &:global(.block-text-image--33)
          flex-basis: 33.33%
    +res-md-max
      +vSpace(2rem)
    +res-sm-max
      margin-left: #{$wcont-padding * -1}
      margin-right: #{$wcont-padding * -1}
  \:global(.block-card-group--empty)
    &::before
      content: "Trascina qui gli elementi TextImage"
  &:not(.root--editMode)
    \:global(.block-card-group--empty)
      display: none

.layout
  +vSpace(4rem)

.main
  +vSpace(1.2rem)
  max-width: 78rem
  margin-left: auto
  margin-right: auto
  text-align: center

  
.title
  +typoH2
  +res-sm-max
    +typoH3Mobile

.description
  +typoTextXl
  +customContent
    
