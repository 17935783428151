@import styles/sass/abstracts

.root
  padding: 4rem 0
  color: $text-black

.layout
  +vSpace(2.4rem)

.title
  +typoH3
  +res-sm-max
    +typoH4Mobile

.slider
  \:global(.swiper)
    +widen($wcont-padding)