@import styles/sass/abstracts

.root
  background: $fill-secondary-90
  color: $text-white
  +vSpace(2rem)
  padding: 2.4rem
  +res-sm-max
    padding: 2rem $wcont-padding
    margin-left: #{$wcont-padding * -1}
    margin-right: #{$wcont-padding * -1}

.main
  +vSpace(.8rem)

.title
  +typoH4
  +res-sm-max
    +typoH5Mobile

.description
  +typoTextSm
  +customContent
  +customContentDarkVariant

.slider
  \:global(.swiper)
    +widen(2.4rem)
    +res-sm-max
      +widen($wcont-padding)
  \:global(.swiper-slide)
    width: 24rem
    +res-sm-max
      width: 30rem

.bottom
  display: flex
  flex-wrap: wrap
  align-items: center
  gap: 2rem
  width: 100%

.prices
  +typoTextSm

.price
  &--current
    +typoBase(2.4rem, 1.5, $fw-extrabold, $ff-secondary)
  &--full
    text-decoration: line-through

.price--full, .priceLabel
  opacity: .7

.cta
  +res-sm-min
    margin-left: auto
  +res-xs-max
    width: 100%

.modal
  max-width: 98.4rem
  width: calc(100% - 4rem)
  .prices
    text-align: center

.modalContent
  +vSpace(2.4rem)

.modalDescription
  +typoTextMd
  +customContent
  +res-sm-max
    +typoTextSm

.modalList
  text-align: left
  +res-lg-min
    display: flex
    flex-wrap: wrap
    gap: 2rem
    > *
      width: calc(50% - 1rem)
  +res-md-max
    +vSpace(.8rem)